import { onBeforeMount, computed, inject } from 'vue'
import { useServicesStore } from '@/state/services'

export const useServices = () => {
  const servicesStore = useServicesStore()
  const services = computed(() => servicesStore.services || [])
  const selectedServices = inject('selected-services')

  onBeforeMount(async () => {
    await servicesStore.loadServices()
    servicesStore.setSelectedServicesFromParams()
  })

  return {
    services
  }
}
