import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { FullService } from "@/static-data/service";

export const useBookingStore = defineStore('booking-store', () => {
  const comment = ref('')
  const images = ref([])
  const giftServicesIds = ref<FullService['id'][]>([])
  const priceByGiftService = ref(0)

  const removeImage = (name) => {
    const oldImageIndex = images.value.findIndex((img) => img.name === name)

    if (oldImageIndex > -1) {
      images.value.splice(oldImageIndex, 1)
    }
  }

  const setImages = (blob, name) => {
    images.value.push({
      name,
      blob
    })
  }

  return {
    setComment: (newVal) => comment.value = newVal,
    setImages,
    removeImage,
    comment,
    images,
    giftServicesIds: {
      get: giftServicesIds,
      set: (ids: FullService['id'][]) => giftServicesIds.value = ids ?? []
    },
    priceByGiftService: {
      get: priceByGiftService,
      set: (val: number) => priceByGiftService.value = val
    }
  }
})
