<script lang="ts" setup>
import { ref, computed, defineEmits } from 'vue'
import { STEPS } from '@/static-data/steps'
import IconCheckMark from "@/components/icons/icon-check-mark.vue";

const emits = defineEmits(['cancel-booking', 'change-step'])
const props = defineProps<{
  currentStepIndex: number
  steps: any[]
}>()

const checkActiveStep = (index: number) => {
  return index <= props.currentStepIndex ? 'pl-bg-black' : 'pl-bg-gray-light'
}
</script>

<template>
  <div class="pl-relative pl-flex pl-justify-between pl-mt-3 pl-max-w-[300px] pl-mx-auto">
<!--    <div-->
<!--      v-for="(step, index) in steps"-->
<!--      :class="[index <= currentStepIndex ? 'pl-bg-black' : 'pl-bg-gray-light']"-->
<!--      class="pl-step-line pl-step-line__first pl-absolute pl-w-[50%] pl-h-[3px] pl-top-[7px] pl-left-[10px]"-->
<!--    ></div>-->
    <div
      :class="currentStepIndex ? 'pl-bg-black' : 'pl-bg-gray-light'"
      class="pl-step-line pl-step-line__first pl-absolute pl-w-[50%] pl-h-[3px] pl-top-[7px] pl-left-[10px]"
    ></div>
    <div
      :class="currentStepIndex > 1 ? 'pl-bg-black' : 'pl-bg-gray-light'"
      class="pl-step-line pl-step-line__second pl-absolute pl-w-[50%] pl-h-[3px] pl-top-[7px] pl-right-[10px]"
    ></div>

    <div
      v-for="(step, index) in steps"
      :key="step.id"
      class="pl-relative pl-cursor-pointer"
      @click="emits('change-step', index)"
    >
      <div
        :class="checkActiveStep(index)"
        class="pl-round pl-step-label pl-mx-auto pl-rounded-[50%] pl-flex pl-items-center pl-justify-center"
      >
        <icon-check-mark></icon-check-mark>
      </div>

      <div class="pl-text-xs">Шаг {{ index + 1 }}</div>
    </div>
  </div>

  <button
    v-if="currentStep === STEPS[0]"
    class="pl-bg-transparent pl-px-5 pl-absolute pl-top-0 pl-right-3 pl-border-[1px] pl-border-solid pl-border-black pl-rounded-[2rem] pl-py-[6px] pl-hidden md:pl-block"
    @click="emits('cancel-booking')"
  >
    {{ isShowCancelBookingModal ? 'Закрыть окно' : 'Отменить запись' }}
  </button>
</template>

<style lang="scss" scoped>
.pl-step-label {
  width: 18px;
  height: 18px;
}
</style>
