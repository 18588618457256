<script lang="ts" setup>
import { ref, defineEmits, computed } from 'vue'
import GetTokenByPhone from '@/components/get-token-by-phone.vue'
import FetchAndCancelBooking from '@/components/fetch-and-cancel-booking.vue'
import PlButton from '@/components/pl-button.vue'
import { isDesktop } from '@/tools/isDesctop'
import ShowCanceledBookingMsg from '@/components/show-canceled-booking-msg.vue'

const props = defineProps<{
  settings: Record<string, any>
}>()

const isModalCancelBookingShow = ref(false)
const isShowCanceledBookingMsg = ref(false)
const isTokenCreate = ref(false)
const emits = defineEmits(['booking-canceled'])
const btnPosition = computed(() => {
  if (!props.settings) {
    return
  }

  const defaultPositionClasses = ['pl-absolute pl-top-0 pl-max-w-[250px] pl-mt-0']

  if (props.settings.position) {
    if (props.settings.position === 'right') {
      defaultPositionClasses.push('pl-right-0')
    }

    return defaultPositionClasses
  }

  return ''
})
</script>

<template>
  <div>
    <div
      v-if="isModalCancelBookingShow"
      :class="isModalCancelBookingShow ? 'pl-absolute pl-top-0 pl-left-0 pl-w-full pl-h-full pl-bg-whitest pl-z-[51]' : ''"
    >
      <div class="pl-max-w-[400px] pl-mx-auto">
        <get-token-by-phone
          v-if="!isTokenCreate && !isShowCanceledBookingMsg"
          emit-auth-event
          @auth-user="isTokenCreate = true"
        />

        <fetch-and-cancel-booking
          v-if="isTokenCreate && !isShowCanceledBookingMsg"
          @booking-canceled="isShowCanceledBookingMsg = !isShowCanceledBookingMsg"
        />

        <show-canceled-booking-msg
          v-if="isShowCanceledBookingMsg"
          @close-modal="isModalCancelBookingShow = !isModalCancelBookingShow"
        />

        <button
          v-if="isModalCancelBookingShow"
          class="pl-bg-transparent pl-border-[1px] pl-border-solid pl-border-black pl-rounded-[2rem] pl-mt-5 pl-w-full pl-py-[10px] pl-mb-5"
          @click="isModalCancelBookingShow = !isModalCancelBookingShow"
        >
          Закрыть окно
        </button>
      </div>
    </div>

    <button
      class="pl-bg-transparent pl-border-[1px] pl-border-solid pl-border-black pl-rounded-[2rem] pl-py-[10px] pl-px-[40px]"
      :class="[btnPosition, isDesktop() ? '' : 'pl-w-full']"
      @click="isModalCancelBookingShow = !isModalCancelBookingShow"
    >
      Отменить запись
    </button>
  </div>
</template>

<style lang="scss" scoped>

</style>
