<script lang="ts" setup>
import InfoBlock from '@/components/info-block.vue'
import PlInput from '@/components/pl-input.vue'
import UnitDescription from '@/components/unit-description.vue'
import PlButton from '@/components/pl-button.vue'
import { useUnitsList } from '@/hooks/units-list'
import Divider from '@/components/divider.vue'
import {inject} from "vue";

const props = defineProps<{
  showNextButton?: boolean,
  settings: Record<string, any>
}>()

const {
  units,
  searchValue,
  filteredUnitsBySearchValue,
  filteredUnitsByMapAndSearchValue,
  selectUnit,
  nextStep,
  isBtnNextStepDisable,
  unitsList,
  emitChangeCity
} = useUnitsList(props)
</script>

<template>
  <info-block
    title="Выберите студию"
    height="300px"
    accordion
    element-id="unit-list"
  >
    <template #header>
      <div class="pl-px-2 pl-mb-3">
        <pl-input
          v-model="searchValue"
          class="pl-mt-3"
          is-can-clear
        />
      </div>
    </template>

    <div
      v-for="unit in filteredUnitsByMapAndSearchValue"
      :key="unit.id"
      class="pl-mt-3"
      :data-unit-id="unit.id"
      @click="selectUnit(unit)"
    >
      <unit-description :unit="unit" />
      <div class="pl-w-fill pl-bg-gray pl-h-[1px] pl-mt-2"></div>
    </div>

    <div>
      <div
        v-if="!searchValue && !filteredUnitsByMapAndSearchValue.length"
        class="pl-text-sm pl-font-semibold pl-px-2 pl-text-center"
      >
        На карте нет ни одной студии
      </div>

<!--      <divider-->
<!--        v-if="!filteredUnitsByMapAndSearchValue.length"-->
<!--        class="pl-my-3"-->
<!--      />-->

      <div
        v-if="searchValue && filteredUnitsBySearchValue.length"
        class="pl-text-sm pl-font-semibold pl-px-2 pl-text-center pl-mt-3"
      >
        Результаты поиска
      </div>

      <div
        v-if="searchValue && !filteredUnitsBySearchValue.length"
        class="pl-text-sm pl-font-semibold pl-px-2 pl-text-center pl-mt-3"
      >
        Не найдено ни одной студии
      </div>

      <pl-button
        v-if="props.settings?.isShowChangeCityBtn && !searchValue && (!filteredUnitsByMapAndSearchValue.length || !filteredUnitsBySearchValue.length)"
        label="Изменить город?"
        @click="emitChangeCity"
      />

      <div
        v-if="!searchValue"
        class="pl-text-sm pl-font-semibold pl-px-2 pl-text-center pl-mt-3"
      >
        Список студий
      </div>

      <div
        v-for="unit in filteredUnitsBySearchValue"
        :key="unit.id"
        class="pl-mt-3"
        :data-unit-id="unit.id"
        @click="selectUnit(unit)"
      >
        <unit-description :unit="unit" />
        <div class="pl-w-fill pl-bg-gray pl-h-[1px] pl-mt-2"></div>
      </div>

      <pl-button
        v-if="props.settings?.isShowChangeCityBtn && searchValue && (!filteredUnitsByMapAndSearchValue.length || !filteredUnitsBySearchValue.length)"
        label="Изменить город?"
        @click="emitChangeCity"
      />
    </div>

    <template #footer>
      <pl-button
        :disable="isBtnNextStepDisable"
        label="Выбрать дату и мастера"
        class="pl-hidden md:pl-block"
        @click="nextStep"
      />
    </template>
  </info-block>
</template>
