import type { Unit } from '@/static-data/unit'
import type { City } from '@/static-data/city'
import { TYPE_PRICE_CATEGORY_NUMBER } from '@/static-data/window'

export interface FullService extends Service{
  service_category_id: number,
  duration: number,
  is_countable: boolean,
  priority: number,
  popular: number,
  price: null
  categoryTitle?: string
}

export interface Service {
  id: number
  title: string
  services: FullService[]
  images: { url: string }[]
  userSelect?: boolean
}

export const SMART_PEDICURE_IDS = 1121
export const MANICURE_ID = 7
export const SERVICE_GROUP_MANICURE_ID = 12
export const PEDICURE_ID = 99
export const SMART_PEDICURE_ID = 1121

export interface getServiceWithPriceParams {
  unitId : Unit['id']
  cityId: City['id']
  serviceId: Service['id']
}

export interface ServiceImage {
  id: number
  disk: string
  filename: string
  sort: number
  created_at: string
  updated_at: string
  deleted_at: null
  type: null
}

export interface ServicePrice {
  service_id: number
  employer_id: number
  duration: number
  price: string
}

export interface ServiceWithPrice {
  id: number
  service_category_id: number
  title: string
  title_online: string
  title_cashier: string
  article: string
  barcode: string
  duration: number
  created_at: string
  updated_at: string
  group: null,
  subtitle: string
  popular: number
  is_countable: true,
  show_aggregator: true,
  priority: number
  deleted_at: null,
  ignore_window_ratio: number
  show_crm: true,
  for_visits: number
  ignore_windows: number
  service_sub_category_id: null,
  show_info: false,
  info: string
  prices: {
    [TYPE_PRICE_CATEGORY_NUMBER.MASTER_NEW]: ServicePrice
    [TYPE_PRICE_CATEGORY_NUMBER.MASTER]: ServicePrice
    [TYPE_PRICE_CATEGORY_NUMBER.TOP_MASTER]: ServicePrice
  },
  service_category: {
    id: number
    title: string
    created_at: string
    updated_at: string
    show_aggregator: boolean
    deleted_at: null
    alias: null
    parent_id: null
    priority: number
  },
  images: ServiceImage[]
}

export interface GiftServiceTopMaster {
  service: ServiceWithPrice,
  employer: Record<string, any>
  begin_at: string
  end_at: string
  price_before: number
  price: number
  quantity: number
}
