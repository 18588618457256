import { useUserStore } from '@/state/user'
import { useUnitsStore } from '@/state/units'
import { useEmployerCategoryGroup } from '@/state/employer-category-group'
import { useWindowsStore } from '@/state/windows'
import { useServicesStore } from '@/state/services'
import { useDateStore } from '@/state/date'
import { BookingApi } from '@/service/booking'
import { UrlParse } from '@/tools/url-parse'
import type { Booking } from '@/static-data/booking'
import { DateTime } from 'luxon'
import { metrikaGoal } from '@/tools/metrika'
import { useCitiesStore } from '@/state/cities'
import {useBookingStore} from "@/state/booking";
import { useSettingsStore } from '@/state/settings'
import type { CheckPromoResponse } from '@/static-data/booking'
import type { Service } from '@/static-data/service'

export function useBookingMiddleware () {
  const bookingApi = new BookingApi()
  const userStore = useUserStore()
  const settingsStore = useSettingsStore()
  const bookingStore = useBookingStore()
  const unitsStore = useUnitsStore()
  const servicesStore = useServicesStore()
  const dateStore = useDateStore()
  const employerCategoryGroup = useEmployerCategoryGroup()
  const windowsStore = useWindowsStore()
  const citiesStore = useCitiesStore()
  const urlParse = new UrlParse()

  const generateBookingParams = () => {
    const params = {
      user_id: userStore.user.id,
      unit_id: unitsStore.selectedUnit.id,
      employer_category_group_ids: employerCategoryGroup.selectedEmployerCategoryGroup,
      mobile: false,
      is_aggregator: false,
      booking_source_id: 2,
      waiting_list_booking_id: null,
      has_utm: urlParse.hasUtm() ? 1 : 0,
      utm_source: urlParse.getUtmSource(),
      utm_medium: urlParse.getUtmMedium(),
      utm_campaign: urlParse.getUtmCampaign(),
      booking_type: windowsStore.windowsOrSchedules.type,
      service_ids: Object.keys(servicesStore.selectedServices),
      parallel: windowsStore.parallel ? 1 : 0,
    }
    const calltouch_session_id = settingsStore.calltouchSessionId.get() || (window.ct && window.ct("calltracking_params", "kde6496x")?.sessionId)

    if (calltouch_session_id) {
      params.calltouch_session_id = calltouch_session_id
    }

    if (bookingStore.giftServicesIds.get.length) params.gift_service_ids = bookingStore.giftServicesIds.get
    if (bookingStore.comment) params.client_comment = bookingStore.comment
    if (bookingStore.images) params.images = bookingStore.images.map((img) => img.blob)

    if (windowsStore.promoCode) {
      params.promo_code = windowsStore.promoCode
    }

    if (params.booking_type === 'windows') {
      const currentWindow = windowsStore.getSelectedWindowOrSchedule
      params.window_ids = currentWindow.map(item => item.id)
      params.begin_at = dateStore.date.startOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
      params.end_at = dateStore.date.endOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
      params.service_ids = Object.keys(servicesStore.selectedServices).map(item => Number(item))
    } else {
      const selected = []
      const currentWindow = windowsStore.getSelectedWindowOrSchedule
      currentWindow.forEach((window) => {
        selected.push({
          id: window.id,
          service_ids: window.services.map((item) => item.id),
          ranges: [window.begin_at, window.end_at],
          serviceCategoryId: window.services[0].service_category_id
        })
      })
      params.begin_at = dateStore.date.startOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
      params.end_at = dateStore.date.endOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
      params.schedules = selected.sort((a, b) => a.serviceCategoryId - b.serviceCategoryId).map((item) => {
        delete item.serviceCategoryId
        return item
      })
    }

    return params
  }

  const createBooking = async () => {
    const params = generateBookingParams()

    const { data: { booking, gift_services } } = await bookingApi.createBooking(params, userStore.user.token)
    metrikaGoal('user_booking', { [params.user_id]: params })
    return {
      booking,
      gift_services,
    }
  }

  const getBookings = async () => bookingApi.getBookings(userStore.user.token)
  const cancelBooking = async (id: Booking['id']) => bookingApi.cancelBooking(id, userStore.user.token)
  const sendPromoCode = async (promoCode: string, price: string): Promise<{ data: CheckPromoResponse }> => {
    return bookingApi.sendPromoCode(promoCode, userStore.user.token, price, windowsStore.getMapSelectedService, windowsStore.currentCompanyId)
  }

  const checkGiftServiceByTopMaster = async (promoCode: string, giftServices: number[]) => {
    const serviceResult = []

    windowsStore.getSelectedWindowOrSchedule.forEach((item) => {
      item.services.forEach((localService) => {
        serviceResult.push({
          service_id: localService.id,
          employer_id: item.employer_id,
          begin_at: item.begin_at,
          end_at: item.end_at
        })
      })

      return item
    })
    return bookingApi.checkGiftServiceByTopMaster(promoCode, userStore.user.token, serviceResult, giftServices)
  }

  const sendPromoCodeByGiftService = async (promoCode: string, price: string, giftServiceId: Service['id']): Promise<{ data: CheckPromoResponse }> => {
    return bookingApi.sendPromoCodeByGiftService(promoCode, userStore.user.token, price, windowsStore.getMapSelectedService, windowsStore.currentCompanyId, giftServiceId)
  }
  const sendReferId = async () => {
    if (userStore.user.referenceId) {
      try {
        await bookingApi.sendReferId(userStore.user.referenceId, userStore.user.phone, userStore.user.token)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return {
    createBooking,
    getBookings,
    cancelBooking,
    sendReferId,
    sendPromoCode,
    sendPromoCodeByGiftService,
    checkGiftServiceByTopMaster
  }
}
