<script lang="ts" setup>
import { ref, computed } from 'vue'
import PlInput from '@/components/pl-input.vue'
import PlButton from '@/components/pl-button.vue'
import { useWindowsStore } from '@/state/windows'
import AcceptIcon from '@/components/icons/accept.vue'
import CancelIcon from '@/components/icons/cancel.vue'
import { useSettingsStore } from '@/state/settings'
import { useGiftService } from '@/composible/giftServices'
import type { Service } from '@/static-data/service'
import Modal from '@/components/modal.vue'
import { useServicesStore } from '@/state/services'
import PlCheckbox from '@/components/pl-checkbox.vue'
import { useBookingStore } from '@/state/booking'

const servicesStore = useServicesStore()
const settingsStore = useSettingsStore()
const windowsStore = useWindowsStore()
const bookingStore = useBookingStore()
const promoCode = ref('')
const promoError = ref(false)
const serviceForGift = ref<Service['id'][]>([])
const { giftServiceNamesTemplate } = useGiftService()
const selectedGiftService = ref<Service | null>(null)
const isAddedGiftServices = ref(false)

const giftServiceForSelect = computed(() => {
  if (serviceForGift.value) {
    return servicesStore.findServiceByIds(serviceForGift.value)
  }

  return []
})

const sendPromo = async () => {
  try {
    const localServiceForGift = await windowsStore.addPromo(promoCode.value)

    if (localServiceForGift) serviceForGift.value = localServiceForGift

    await windowsStore.checkGiftServiceByTopMaster(promoCode.value)
  } catch (e) {
    if (e?.response?.status !== 422) {
      promoError.value = true
    }
  }
}

const cancelPromoCode = () => {
  windowsStore.resetPromo()
  promoCode.value = ''
  isAddedGiftServices.value = false
}

const setSelectedGiftService = async () => {
  await windowsStore.addPromoByGiftService(promoCode.value, selectedGiftService.value.id)
  serviceForGift.value = []
  // servicesStore.addOrDeleteSelectedService(selectedGiftService.value)
  isAddedGiftServices.value = true
  await windowsStore.checkGiftServiceByTopMaster(promoCode.value)
}
</script>

<template>
  <div>
    <div class="pl-text-center pl-mt-8">
      Если у вас есть промокод, введите его для получения скидки
    </div>

    <div v-if="windowsStore.sumForPromo || isAddedGiftServices">
      Промокод активирован
      <br>
      <div
        v-if="bookingStore.priceByGiftService.get"
        class="pl-text-green"
      >
        Выполнение подарочной услуги ТОП-МАСТЕРОМ, оплачивается дополнительно, в размере {{ bookingStore.priceByGiftService.get }} {{ settingsStore.currency.currentCurrency }}
      </div>

      <div class="pl-text-green">
        Сумма заказа: <span class="pl-font-semibold">{{ windowsStore.sumForPromo + bookingStore.priceByGiftService.get }} {{ settingsStore.currency.currentCurrency }}</span>
      </div>

      <div
        v-if="giftServiceNamesTemplate"
        class="pl-text-green"
        v-html="giftServiceNamesTemplate"
      >
      </div>
    </div>

    <pl-input
      without-icon
      class="pl-mt-3"
      v-model="promoCode"
      placeholder="Введите промокод"
    />

    <div
      v-if="windowsStore.sumForPromo || isAddedGiftServices"
      class="pl-text-green-second pl-text-sm pl-mt-2 pl-flex pl-items-center"
    >
      <accept-icon class="pl-mr-2" />
      Промокод применен успешно
    </div>

    <div
      v-if="promoError"
      class="pl-text-red pl-text-sm pl-mt-2 pl-flex pl-items-center"
    >
      <cancel-icon class="pl-mr-2" />
      Этот промокод не активен
    </div>

    <pl-button
      v-if="!windowsStore.sumForPromo && !isAddedGiftServices"
      label="отправить промо-код"
      :disabled="windowsStore.sumForPromo && isAddedGiftServices"
      @click="sendPromo"
    />

    <pl-button
      v-if="windowsStore.sumForPromo || isAddedGiftServices"
      label="Отменить промо-код"
      @click="cancelPromoCode"
    />
  </div>

  <modal
    :model-value="!!serviceForGift.length"
  >
    <pl-checkbox
      v-for="service in giftServiceForSelect"
      :key="service.id"
      :model-value="selectedGiftService?.id === service.id"
      class="pl-mt-3"
      @update:model-value="selectedGiftService = service"
    >
      <div class="">
        <span>{{ service.title }}</span>
        <span class="pl-text-sm pl-text-gray pl-ml-2">({{ service.categoryTitle }})</span>
      </div>
    </pl-checkbox>

    <pl-button
      label="отправить промо-код"
      :disabled="!selectedGiftService"
      @click="setSelectedGiftService"
    />
  </modal>
</template>

<style lang="scss" scoped>

</style>
