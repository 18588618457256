<template>
  <svg width="130" height="129" viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M64.9998 15.0498C37.7341 15.0498 15.5498 37.2341 15.5498 64.4998C15.5498 91.7655 37.7341 113.95 64.9998 113.95C92.2655 113.95 114.45 91.7655 114.45 64.4998C114.45 37.2341 92.2655 15.0498 64.9998 15.0498Z" fill="#2CC13B"/>
    <path opacity="0.1" d="M65 0C29.4361 0 0.5 28.9361 0.5 64.5C0.5 100.064 29.4361 129 65 129C100.564 129 129.5 100.064 129.5 64.5C129.5 28.9361 100.564 0 65 0Z" fill="#2CC13B"/>
    <g clip-path="url(#clip0_2575_18351)">
      <path d="M64.4625 30.1001C45.4951 30.1001 30.0625 45.5327 30.0625 64.5001C30.0625 83.4675 45.4951 98.9001 64.4625 98.9001C83.4299 98.9001 98.8625 83.4675 98.8625 64.5001C98.8625 45.5327 83.4299 30.1001 64.4625 30.1001Z" fill="#2CC13B"/>
      <path d="M61.7033 77.2599L83.6882 55.4474C85.0677 54.068 85.0677 51.9126 83.6882 50.5331C82.3088 49.1537 80.1534 49.1537 78.7739 50.5331L59.1168 70.1903L49.8917 61.7411C48.5123 60.4479 46.3569 60.5341 45.0637 61.9136C43.8566 63.293 43.9429 65.4484 45.3223 66.7416L56.9614 77.3462C58.3409 78.6394 60.41 78.5532 61.7033 77.2599Z" fill="#F4F6FD"/>
    </g>
    <defs>
      <clipPath id="clip0_2575_18351">
        <rect width="68.8" height="68.8" fill="white" transform="translate(30.0625 30.1001)"/>
      </clipPath>
    </defs>
  </svg>
</template>
