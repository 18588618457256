<script lang="ts" setup>
import { ref, inject } from 'vue'
import PlCheckbox from '@/components/pl-checkbox.vue'
import InfoBlock from '@/components/info-block.vue'
import { useServicesStore } from '@/state/services'
import Modal from '@/components/modal.vue'
import PlButton from '@/components/pl-button.vue'

const changeStep = inject('change-step')
const isModalPrevStepShow = ref(false)
const serviceStore = useServicesStore()
const selectedServices = serviceStore.selectedServices

const changeStepLocal = () => {
  isModalPrevStepShow.value = false
  changeStep(0)
}
</script>

<template>
  <div>
    <info-block
      title="Выбранные услуги"
      accordion
      class-list="pl-pb-3"
      @click="isModalPrevStepShow = true"
    >
      <pl-checkbox
        v-for="(value, key) in selectedServices"
        :key="value.id"
        :modelValue="true"
        class="pl-mt-2"
        disabled
      >
        {{ value.title }}
      </pl-checkbox>
    </info-block>

    <modal
      v-model="isModalPrevStepShow"
    >
      <div class="pl-font-semibold">Выбранные услуги</div>
      <div class="pl-mt-3">
        Услуги можно изменить только <br> на первом шаге
      </div>

      <pl-button
        label="Изменить"
        @click="changeStepLocal(0)"
      />

      <button
        class="pl-bg-transparent pl-border-[1px] pl-border-solid pl-border-black pl-rounded-[2rem] pl-py-[8px] pl-w-full pl-mt-3"
        @click="isModalPrevStepShow = false"
      >
        Закрыть
      </button>
    </modal>
  </div>
</template>

<style lang="scss" scoped>

</style>
