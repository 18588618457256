export enum TYPE_PRICE_CATEGORY {
  MASTER_NEW = 'new',
  MASTER = 'default',
  TOP_MASTER = 'top'
}

export enum TYPE_PRICE_CATEGORY_NUMBER {
  MASTER_NEW = '1',
  MASTER = '2',
  TOP_MASTER = '3'
}

export interface Window {
  id: number
  company_id: number
  unit_id: number
  employer_id: number
  employer_avatar: string
  workplace_id: number
  type_position: string
  type_price_category: `${TYPE_PRICE_CATEGORY}`,
  type_price_group: `${TYPE_PRICE_CATEGORY}`,
  first_name: string
  middle_name: string
  last_name: string
  rating: string
  voices: number
  reviews_count: number
  unit_name: string
  begin_at: number
  is_visit: boolean
  end_at: number
  begin_h: string
  end_h: string
  show_detailed: boolean
  discount: null,
  services: [
    {
      schedule_id: number
      id: number
      service_category_id: number
      priority: number
      title: string
      employer_id: number
      ignore_window_ratio: number
      duration: number
      price: string
      quantity: number
    }
  ],
  has_vaccinated: boolean
}
