import { HttpClient } from '@/tools/http-client'
import type { Unit } from '@/static-data/unit'

export class UnitsApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public fetchAllUnits = async (id: string | number = 1): Promise<Unit[]> => {
    const {data} = await this.instance.get(`/api/external/v1/units/city`, {
      params: {
        city_id: id
      }
    })

    return data
  }
}
