import { HttpClient } from '@/tools/http-client'
import { useStore } from '@/state/store'
import { DateTime } from 'luxon'

export const SERVER_FORMAT_DATE = 'yyyy-MM-dd HH:mm:ss'

export class WindowsApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public fetchWindows = async (params): Promise<any> => {
    const {data} = await this.instance.get(`/api/external/v1/windows`, {params})

    return data
  }
}
