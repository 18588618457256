import type { Window } from '@/static-data/window'

export const rating = (rating: string) => {
  const employerRating = +Number(rating).toFixed(2);

  return employerRating >= 4.9 ? 5 : employerRating;
}

export const typePosition = (window: Window) => {
  switch (window.type_position) {
    case 'top_master':
      return 'Топ мастер'
    case 'new_master':
      return 'New мастер'
    default:
      return 'Мастер'
  }
}
