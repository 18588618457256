<script lang="ts" setup>
import { useEmployerCategoryGroup } from '@/state/employer-category-group'
import InfoBlock from '@/components/info-block.vue'
import PlCheckbox from '@/components/pl-checkbox.vue'

const {
  employerCategoryGroup,
  toggleEmployerCategoryGroup,
  selectedEmployerCategoryGroup
} = useEmployerCategoryGroup()
</script>

<template>
<!--  :defaultHidden="!currentStep"-->
  <info-block
    title="Выберите категорию мастера"
    accordion
    class-list="pl-mt-5"
  >
    <pl-checkbox
      v-for="(group, index) in employerCategoryGroup"
      :key="group.id"
      :modelValue="selectedEmployerCategoryGroup.includes(group.id)"
      class="pl-mt-3"
      :class="index + 1 === employerCategoryGroup.length ? 'pl-mb-3' : ''"
      @update:modelValue="toggleEmployerCategoryGroup(group.id)"
    >
      <div class="">
        <span>{{ group.name }}</span>
      </div>
    </pl-checkbox>
  </info-block>
</template>

<style lang="scss" scoped>

</style>
