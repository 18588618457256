import { HttpClient } from '@/tools/http-client'
import type { getServiceWithPriceParams, Service, ServiceWithPrice } from '@/static-data/service'

export class ServicesApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public fetchServices = async (id: number = 1): Promise<Service[]> => {
    const {data} = await this.instance.get(`/api/external/v1/services/default`, {
      params: {
        city_id: id
      }
    })

    return data
  }

  getServiceWithPrice = async (params: getServiceWithPriceParams): Promise<ServiceWithPrice> => {
    const {data} = await this.instance.get(`/api/external/v1/services/with_prices`, {
      params: {
        city_id: params.cityId,
        id: params.serviceId,
        unit_id: params.unitId,
      }
    })

    return data
  }
}
