<script lang="ts" setup>
import { withDefaults, ref, onMounted, useSlots } from 'vue'
import IconArrowRight from "@/components/icons/icon-arrow-right.vue";

const props = withDefaults(defineProps<{
  title?: string
  height?: string
  classList?: string
  accordion?: boolean
  defaultHidden?: boolean
  maxHeight?: string
  elementId?: number
}>(), {
  title: '',
  height: 'auto',
  classList: '',
  accordion: false,
  defaultHidden: false,
  maxHeight: '300px',
  elementId: undefined
})
const slots = useSlots()
const isBlockHidden = ref(false)

const toogleBlock = () => {
  if (!props.accordion) {
    return
  }

  isBlockHidden.value = !isBlockHidden.value
}

const hasSlot = (name: string) => !!slots[name]

onMounted(() => {
  if (props.defaultHidden && props.accordion) {
    isBlockHidden.value = true
  }
})
</script>

<template>
  <div
    class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md"
    :class="classList"
  >
    <div
      v-if="title"
      class="pl-flex pl-items-center pl-justify-between pl-p-2 pl-cursor-pointer"
      @click="toogleBlock"
    >
      <div class="pl-text-md pl-font-semibold">{{ title }}</div>
      <icon-arrow-right
          v-if="accordion"
          class="pl-transition-all"
          :class="isBlockHidden ? 'pl-rotate-90' : ''"
      >

      </icon-arrow-right>
    </div>

    <slot name="header" />

    <div
      class="pl-overflow-scroll pl-animation-block pl-px-2 scroll-block"
      :style="{ 'max-height': isBlockHidden ? '' : maxHeight }"
      :class="isBlockHidden ? 'scroll-block__hidden' : `scroll-block__scroll`"
      :id="elementId ? elementId : 0"
    >
      <slot />
    </div>

    <div
      v-if="hasSlot('footer')"
      class="pl-px-2 pl-pb-2"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scroll-block {
  &__hidden {
    max-height: 0;
  }

  &__scroll {
    max-height: 300px;
  }
}
</style>
