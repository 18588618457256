<script setup lang="ts">

</script>

<template>
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.75 19.4016H12.55L7.87749 22.8057C7.18449 23.3118 6.25 22.7714 6.25 21.8513V19.4016C3.1 19.4016 1 17.1014 1 13.6511V6.75046C1 3.30018 3.1 1 6.25 1H16.75C19.9 1 22 3.30018 22 6.75046V13.6511C22 17.1014 19.9 19.4016 16.75 19.4016Z" fill="#504D49" stroke="#504D49" stroke-width="1.64979" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.0001 12V11.6733C11.0001 10.6156 11.7592 10.0556 12.5182 9.60447C13.2592 9.16891 14 8.60893 14 7.58226C14 6.15115 12.6628 5 11.0001 5C9.33739 5 8 6.15115 8 7.58226" fill="#504D49"/>
    <path d="M11.0001 12V11.6733C11.0001 10.6156 11.7591 10.0556 12.5182 9.60447C13.2592 9.16891 14 8.60893 14 7.58226C14 6.15115 12.6628 5 11.0001 5C9.33739 5 8 6.15115 8 7.58226" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="11" cy="15" r="1" fill="white"/>
  </svg>
</template>

<style scoped lang="scss">

</style>
