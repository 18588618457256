import { HttpClient } from '@/tools/http-client'

export class UserApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public authUser = async (code: string, phone: string): Promise<any> => {
    const { data: { token }} = await this.instance.post(`/api/external/v1/auth/login`, {
      code,
      phone: phone.replaceAll(/\D/gi, '')
    })

    return token
  }

  public getUser = async (token: string): Promise<any> => {
    const { data } = await this.instance.get(`/api/external/v1/users/iam`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })

    return data
  }

  public updateUser = async (token: string, params: Record<string, any>): Promise<any> => {
    const { data } = await this.instance.put(`/api/external/v1/users`, params, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })

    return data
  }
}
