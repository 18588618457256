import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings-store', () => {
  let settings = ref<Record<string, any> | null>(null)
  const currentCurrency = ref('₽')
  const calltouchSessionId = ref<string | number | null>(null)

  const setSettings = (newSettings: Record<string, any>) => settings.value = newSettings
  const setCurrency = (newValue: string) => currentCurrency.value = newValue

  return {
    settings,
    setSettings,
    currency: {
      setCurrency,
      currentCurrency,
    },
    calltouchSessionId: {
      get: () => calltouchSessionId.value,
      set: (newVal: string | number) => calltouchSessionId.value = newVal
    }
  }
})
