import { computed } from 'vue'
import { useBookingStore } from '@/state/booking'
import { useServicesStore } from '@/state/services'

export const useGiftService = () => {
  const bookingStore = useBookingStore()
  const servicesStore = useServicesStore()

  const giftServiceNamesTemplate = computed(() => {
    console.log('bookingStore.giftServicesIds.get', bookingStore.giftServicesIds.get)
    if (bookingStore.giftServicesIds.get.length) {
      const giftServices = servicesStore.findServiceByIds(bookingStore.giftServicesIds.get)
      let servicesTitle = 'Подарочная услуга'

      const serviceTitles = giftServices.map((service) => service.title).join(', ')

      if (bookingStore.giftServicesIds.get.length > 1) servicesTitle = 'Подарочные услуги'

      return `${servicesTitle}: <span class="pl-font-semibold">${serviceTitles}.</span>`
    }

    return ''
  })

  return {
    giftServiceNamesTemplate,
  }
}
