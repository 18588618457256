<script setup lang="ts">

</script>

<template>
  <div class="pl-w-full pl-h-[1px] pl-bg-gray" />
</template>

<style scoped lang="scss">

</style>
