import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { User } from '@/static-data/user'

export const useUserStore = defineStore('user-store', () => {
  const user = ref<User | Record<string, any>>({})

  const setUser = (data: User) => {
    user.value = {
      ...user.value,
      ...data
    }
  }

  return {
    setUser,
    user
  }
})
