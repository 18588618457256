import { DateTime } from 'luxon'

const DAY_FORMAT = 'dd.MM.yyyy'
const DAY_FORMAT_WITH_TIME = 'HH:mm dd.MM.yyyy'

export class ConvertDate {
  private date: DateTime

  constructor(date: string) {
    this.date = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss')
  }

  get toDayFormat(): string {
    return this.date.toFormat(DAY_FORMAT)
  }

  get toDayFormatWithTime(): string {
    return this.date.toFormat(DAY_FORMAT_WITH_TIME)
  }
}
