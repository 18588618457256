import { ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { useServicesMiddleware } from '@/middleware/services'
import type { Service, FullService } from '@/static-data/service'
import { useCitiesStore } from '@/state/cities'
import { SMART_PEDICURE_IDS } from '@/static-data/service'

const MANICURE_ID = 12
const PEDICURE_ID = 13
const PEDICURE_SERVICE_ID = 99
export const MANICURE_SERVICE_ID = 7

export const DESIGN_FOR_MANICURE = [1094, 1106, 1098, 1239, 1102, 1090, 44, 1079, 44, 1079]
export const DESIGN_FOR_MANICURE_ONLY = [26, 29]
export const DESIGN_FOR_PEDICURE = [1096, 1108, 1100]

export const SERVICES_FOR_SELECT_FROM_MANICURE = [MANICURE_SERVICE_ID, 55]
export const SERVICES_FOR_SELECT_FROM_PEDICURE = [15, PEDICURE_SERVICE_ID]
const NAIL_EXTENSIONS_ID = 29

export const useServicesStore = defineStore('services-store', () => {
  const citiesStore = useCitiesStore()
  const servicesMiddleware = useServicesMiddleware()
  const services = ref<Service[]>([])
  const selectedServices = reactive<Record<Service['id'], Service>>({})
  const selectedServiceIdsFromProps = ref<Service['id'][]>([])

  const linearServiceList = computed(() => {
    let result: FullService[] = []

    services.value.forEach((parentService) => {
      result = [
        ...result,
        ...parentService.services
      ]
    })

    return result
  })

  const loadServices = async () => services.value = await servicesMiddleware.loadServices(citiesStore.currentCity.id)
  const setSelectedServicesFromParams = () => {
    selectedServiceIdsFromProps.value.forEach((id) => {
      services.value.forEach((parentService) => {
        parentService.services.forEach((localServices) => {
          if (localServices.id === id) {
            addOrDeleteSelectedService(localServices)
          }
        })
      })
    })

    selectedServiceIdsFromProps.value = []
  }

  const removeSelectedServices = (serviceParam: Service) => {
    delete selectedServices[serviceParam.id]

    let isIncludeDesign= false

    Object.keys(selectedServices).forEach((item) => {
      if (DESIGN_FOR_MANICURE.includes(Number(item)) || DESIGN_FOR_PEDICURE.includes(Number(item))) {
        isIncludeDesign = true
      }
    })

    if (isIncludeDesign) {
      return
    }

    Object.keys(selectedServices).forEach((serviceId) => {
      if (!selectedServices[serviceId].userSelect) {
        delete selectedServices[serviceId]
      }
    })

    if (NAIL_EXTENSIONS_ID === serviceParam.id) {
      SERVICES_FOR_SELECT_FROM_MANICURE.forEach((serviceId) => {
        delete selectedServices[serviceId]
      })
    }
  }

  const addSelectedServices = (serviceParam: Service) => {
    selectedServices[serviceParam.id] = {
      ...serviceParam,
      userSelect: true
    }

    if (DESIGN_FOR_MANICURE.includes(serviceParam.id)) {
      selectServiceById(SERVICES_FOR_SELECT_FROM_MANICURE, false)
    }

    if (DESIGN_FOR_MANICURE_ONLY.includes(serviceParam.id)) {
      selectServiceById([MANICURE_SERVICE_ID], false)
    }

    if (DESIGN_FOR_PEDICURE.includes(serviceParam.id)) {
      SERVICES_FOR_SELECT_FROM_PEDICURE.forEach((serviceId) => {
        const parentService = services.value.find((service) => service.id === PEDICURE_ID)
        const currentService = parentService.services.find((oldService) => oldService.id === serviceId)

        if (currentService?.id === PEDICURE_SERVICE_ID && selectedServices[SMART_PEDICURE_IDS]) {
          return
        }

        if (currentService && !selectedServices[currentService.id]) {
          selectedServices[currentService.id] = {
            ...currentService,
            userSelect: false
          }
        }
      })

      // delete selectedServices[SMART_PEDICURE_IDS]
    }
  }
  const addOrDeleteSelectedService = (serviceParam: Service) => {
    selectedServices[serviceParam.id]
      ? removeSelectedServices(serviceParam)
      : addSelectedServices(serviceParam)
  }

  const selectServiceById = (serviceIdsForSelect: number[], setUserSelect?: boolean) => {
    serviceIdsForSelect.forEach((serviceId) => {
      const parentService = services.value.find((service) => service.id === MANICURE_ID)
      const currentService = parentService.services.find((oldService) => oldService.id === serviceId)

      if (currentService && !selectedServices[currentService.id]) {
        selectedServices[currentService.id] = {
          ...currentService,
          userSelect: setUserSelect
        }
      }
    })
  }

  const setSelectedServiceIdsFromProps = (ids: Service['id'][]) => selectedServiceIdsFromProps.value = ids

  const findServiceByIds = (ids: FullService['id'][]): FullService[] => {
    let result: FullService[] = []

    linearServiceList.value.forEach((service) => {
      if (ids.includes(service.id)) {
        const currentCategory = services.value.find((category) => category.id === service.service_category_id) as Service
        result.push({
          ...service,
          categoryTitle: currentCategory.title
        })
      }
    })

    return result
  }

  return {
    services,
    selectedServices,
    loadServices,
    addOrDeleteSelectedService,
    setSelectedServicesFromParams,
    setSelectedServiceIdsFromProps,
    findServiceByIds
  }
})
