<script lang="ts" setup>
import { ref } from 'vue'
import UnitList from '@/components/unit-list.vue'
import UnitsMap from '@/components/units-map.vue'

const unitView = ref('map')

const props = defineProps<{
  settings: Record<string, any>
}>()
</script>

<template>
  <div>
    <div
      class="pl-flex pl-items-center"
    >
      <div
        class="pl-w-2/4 pl-text-center pl-transition-all"
        :class="unitView === 'map' ? 'pl-font-bold' : ''"
        @click="unitView = 'map'"
      >
        Карта
      </div>
      <div
        class="pl-w-2/4 pl-text-center pl-transition-all"
        :class="unitView === 'list' ? 'pl-font-bold' : ''"
        @click="unitView = 'list'"
      >
        Список
      </div>
    </div>

    <div
      class="pl-w-2/4 pl-h-[3px] pl-bg-black pl-transition-all pl-mt-3"
      :class="unitView === 'list' ? 'pl-ml-[50%]' : 'pl-ml-0'"
    ></div>
    <div class="pl-w-full pl-h-[1px] pl-bg-black"></div>

    <unit-list
      v-if="unitView === 'list'"
      class="pl-mt-5"
      :settings="props.settings"
    />

    <units-map
      v-if="unitView === 'map'"
      class="pl-mt-5"
      :settings="props.settings"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>
