<script lang="ts" setup>
import { onMounted, reactive } from 'vue'
import IconClose from '@/components/icons/icon-close.vue'

const props = defineProps<{ price: number }>()
const position = reactive({
  left: 0,
  top: 0
})

onMounted(() => {
  const coords = document.getElementById(`select-service-${props.price}`)?.getBoundingClientRect()
  const desDescriptionWrapper = document.getElementById(`des-description-wrapper`)?.getBoundingClientRect()
  const coordsTop = coords.top - (desDescriptionWrapper.height / 2)

  position.left = coords.left + window.innerWidth > 760 ? 350 : 50
  position.top = coordsTop + desDescriptionWrapper.height > window.innerHeight
    ? window.innerHeight - desDescriptionWrapper.height
    : coordsTop
})
</script>

<template>
  <div
    id="des-description-wrapper"
    class="des-desciption_wrapper"
    :style="{
      left: `${position.left}px`,
      top: `${position.top}px`
    }"
  >
    <icon-close
      class="pl-absolute pl-right-[5px] pl-top-[5px]"
    />
    <section class="pl-flex pl-text-white">
      <div class="pl-w-full" v-if="price === 300">
        <div class="pl-h-max pl-text-sm pl-font-semibold pl-mb-3 pl-align-self-center">Что входит в дизайн 300 ₽</div>
        <div class="pl-text-xs">
          <div class="pl-w-full">
            <h2 itemprop="name" class="pl-text-xs pl-font-semibold pl-text-xs des-desciption_caption">
              Дизайн до 2х ногтей (вкл.)
            </h2>
            <ul class="pl-list-disc pl-ml-3 pl-mt-2">
              <li itemprop="description">Слайдеры</li>
              <li itemprop="description" class="mt-1">Гибкие ленты</li>
              <li itemprop="description">Акварельные капли</li>
              <li itemprop="description">Пирсинг</li>
              <li itemprop="description">Фольга</li>
              <li itemprop="description">Паутинка</li>
              <li itemprop="description">Простая художественная роспись</li>
            </ul>
          </div>

          <div class="pl-w-full pl-mt-3">
            <h2 itemprop="name" class="pl-text-xs pl-font-semibold pl-text-xs des-desciption_caption">Дизайн на 10 ногтей</h2>
            <ul class="pl-list-disc pl-ml-3 pl-mt-2">
              <li itemprop="description">Точки до 20 шт.</li>
              <li itemprop="description">Стразы и заклепки до 10 шт.</li>
              <li itemprop="description">Растяжка блестками</li>
              <li itemprop="description">Браш</li>
              <li itemprop="description">Фольга</li>
              <li itemprop="description">Брызги кистью</li>
              <li itemprop="description">Пайетки и камифубики до 10 шт. на все пальцы</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="" v-if="price === 500">
        <div class="pl-h-max pl-text-sm pl-font-semibold pl-mb-3">Что входит в дизайн 500 ₽</div>
        <div class="pl-text-xs">
          <div class="pl-w-full">
            <h2 itemprop="name" class="pl-text-xs pl-font-semibold pl-text-xs des-desciption_caption">
              Дизайн до 5 ногтей (вкл.)
            </h2>
            <ul class="pl-list-disc pl-ml-3 pl-mt-2">
              <li itemprop="description">Любой дизайн</li>
              <li itemprop="description">Стразы до 25 шт.</li>
            </ul>
          </div>

          <div class="pl-w-full pl-mt-3">
            <h2 itemprop="name" class="pl-text-xs pl-font-semibold pl-text-xs des-desciption_caption">Дизайн на 10 ногтей</h2>
            <ul class="pl-list-disc pl-ml-3 pl-mt-2">
              <li itemprop="description">Френч</li>
              <li itemprop="description">Обратный френч</li>
              <li itemprop="description">Лунки</li>
              <li itemprop="description">Втирка</li>
              <li itemprop="description">Юкки</li>
              <li itemprop="description">Стемпинг</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="pl-w-full" itemscope itemtype="https://schema.org/Offer" v-if="price === 1000">
        <div class="pl-h-max pl-text-sm pl-font-semibold pl-mb-3 pl-align-self-center">Что входит в дизайн 1000 ₽</div>
        <div class="pl-flex pl-text-xs pl-flex-column md:pl-flex-row">
          <div class="pl-w-full">
            <h2 itemprop="name" class="pl-text-xs pl-font-semibold pl-text-xs des-desciption_caption">Дизайн на 10 ногтей</h2>
            <ul class="pl-list-disc pl-ml-3 pl-mt-2">
              <li itemprop="description">Любой дизайн на 10 пальцев</li>
              <li itemprop="description">Стразы до 25 шт.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
.des-desciption_wrapper {
  width: max-content;
  background-color: #302f2f;
  padding: 20px;
  border-radius: 4px;
  z-index: 9999;
  position: fixed;
  right: 0;

  @media (min-width: 768px) {
    min-width: 340px;
  }
}

.des-desciption_caption {
  padding: 8px;
  background: rgba(255, 255, 255, 0.17);
  border-radius: 5px;
  white-space: nowrap;
  width: max-content;
}
</style>
