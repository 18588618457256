<script lang="ts" setup>
import { computed } from 'vue'
import { useWindowsStore } from '@/state/windows'
import { rating, typePosition } from '@/tools/master-tools'
import IconStar from '@/components/icons/icon-star.vue'
import IconCheckMark from '@/components/icons/icon-check-mark.vue'
import type { Window } from '@/static-data/window'
import { useSettingsStore } from '@/state/settings'

const windowsStore = useWindowsStore()
const imageUrl = import.meta.env.PL_CDN_URL
const settingsStore = useSettingsStore()

const setSelectedMasterAndTime = (windows) => {
  return windowsStore.setSelectedWindowParallel(windows)
}

const checkSelectedWindow = (time) => {
  if (!windowsStore.getSelectedWindowOrScheduleFullObj) {
    return
  }

  let result = false

  if (windowsStore.getSelectedWindowOrScheduleFullObj.time === time.time) {
    time.windows.forEach((localWindow, index) => {
      if (!index) {
        result = windowsStore.getSelectedWindowOrSchedule[index].id === localWindow.id
      }

      if (index && result) {
        result = windowsStore.getSelectedWindowOrSchedule[index].id === localWindow.id
      }
    })
  }

  return result
}
</script>

<template>
  <div
    v-for="(windows, index) in windowsStore.filteredMasters"
    :key="windows.windowsByMasters[0].id"
    class="pl-mt-3"
    @click="windowsStore.setSelectedMaster(windows)"
  >
    <div
      v-for="masterWindow in windows.windowsByMasters"
      class="pl-mt-3"
    >
      <div
        :class="index + 1 === windowsStore.filteredMasters.length ? 'pl-mb-3' : ''"
        class="pl-flex pl-items-center pl-justify-between hover:pl-text-hover-gray pl-cursor-pointer"
      >
        <div class="pl-flex pl-items-center pl-text-sm">
          <div class="pl-mr-3">
            <img class="pl-max-w-[45px] pl-max-h-[45px] pl-rounded-md" :src="`${imageUrl}/${masterWindow.employer_avatar}`" alt="">
          </div>
          <div>
            <div class="pl-font-semibold">
              {{ masterWindow.first_name }} {{ !settingsStore.settings?.isMasterSecondNameHidden ? `${masterWindow.last_name[0]}.` : '' }}.
            </div>
            <div class="pl-flex pl-items-center">
              <icon-star class="pl-mr-1"></icon-star>
              {{ rating(masterWindow.rating) }}
              {{ typePosition(masterWindow) }}
            </div>
          </div>
        </div>

<!--        <div-->
<!--          v-if="masterWindow.employer_id === windowsStore.selectedMaster?.employer_id"-->
<!--          class="pl-unit-active__icon pl-rounded-[50%] pl-w-[18px] pl-h-[18px] pl-bg-black pl-flex pl-items-center pl-justify-center"-->
<!--        >-->
<!--          <icon-check-mark></icon-check-mark>-->
<!--        </div>-->
      </div>
    </div>

    <div class="pl-flex pl-flex-wrap pl-items-center pl-mt-3">
      <div
        v-for="time in windows.times"
        :class="checkSelectedWindow(time) ? 'pl-bg-black pl-text-white' : ''"
        class="pl-text-sm pl-border-[1px] pl-border-solid pl-border-black pl-rounded-md pl-mb-2 pl-mr-2 pl-h-[30px] pl-items-center pl-justify-center pl-flex hover:pl-text-hover-gray pl-cursor-pointer pl-w-[60px]"
        @click="setSelectedMasterAndTime(time)"
      >
        {{ time.time }}
      </div>
    </div>

    <div
      v-if="index + 1 !== windowsStore.filteredMasters.length"
      class="pl-h-[1px] pl-bg-gray-light-2 pl-mt-3"
    ></div>
  </div>
</template>

<style lang="scss" scoped>

</style>
