<script lang="ts" setup>
import { defineEmits } from 'vue'
import IconCheckMark from "@/components/icons/icon-check-mark.vue";
const props = defineProps<{
  modelValue: boolean
  disabled?: boolean
}>()

const emits = defineEmits(['update:modelValue', 'click'])
const updateModel = () => {
  if (props.disabled) {
    emits('click')
    return
  }
  emits('update:modelValue', !props.modelValue)
}
</script>

<template>
  <div
    class="pl-relative pl-flex pl-items-center pl-cursor-pointer"
    :style="{ opacity: disabled ? 0.5 : 1 }"
  >
    <div
      :class="modelValue ? 'pl-bg-black' : 'pl-bg-gray-200'"
      class="pl-min-w-[18px] pl-w-[18px] pl-h-[18px] pl-rounded-sm pl-mr-2 pl-flex pl-items-center pl-justify-center"
      @click="updateModel"
    >
      <icon-check-mark v-show="modelValue"></icon-check-mark>
    </div>
    <input
      :disabled="disabled"
      class="pl-hidden"
      type="checkbox"
    >
    <div class="pl-flex pl-items-center pl-justify-center">
      <label
        class="pl-cursor-pointer"
        @click="updateModel"
      >
        <slot />
      </label>
      <slot name="desc" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
