<script lang="ts" setup>
import InfoBlock from '@/components/info-block.vue'
import ServiceBlock from '@/components/service-block.vue'
import { useServices } from '@/hooks/services'

const { services } = useServices()
const props = defineProps<{
  settings: Record<string, any>
}>()

const isBlockClose = (id: number) => props.settings.closeServices.includes(id)
</script>

<template>
  <info-block
    title="Выберите услугу"
    accordion
  >
    <service-block
      v-for="(service, index) in services"
      :key="service.id"
      :title="service.title"
      :services="service.services"
      :service-id="service.id"
      :default-hidden="isBlockClose(service.id)"
      :settings="settings"
    />
  </info-block>
</template>

<style lang="scss" scoped>

</style>
