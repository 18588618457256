<script lang="ts" setup>
import { useWindowsStore } from '@/state/windows'
import { rating, typePosition } from '@/tools/master-tools'
import IconStar from '@/components/icons/icon-star.vue'
import IconCheckMark from '@/components/icons/icon-check-mark.vue'
import { useSettingsStore } from '@/state/settings'

const settingsStore = useSettingsStore()

const windowsStore = useWindowsStore()
const imageUrl = import.meta.env.PL_CDN_URL
</script>

<template>
  <div
    v-for="(window, index) in windowsStore.filteredMasters"
    :key="window.id"
    class="pl-mt-3"
    @click="windowsStore.setSelectedMaster(window)"
  >
    <div
      :class="index + 1 === windowsStore.filteredMasters.length ? 'pl-mb-3' : ''"
      class="pl-flex pl-items-center pl-justify-between hover:pl-text-hover-gray pl-cursor-pointer"
    >
      <div class="pl-flex pl-items-center pl-text-sm">
        <div class="pl-mr-3">
          <img class="pl-max-w-[45px] pl-max-h-[45px] pl-rounded-md" :src="`${imageUrl}/${window.employer_avatar}`" alt="">
        </div>
        <div>
          <div class="pl-font-semibold">
            {{ window.first_name }} {{ !settingsStore.settings?.isMasterSecondNameHidden ? `${window.last_name[0]}.` : '' }}
          </div>
          <div class="pl-flex pl-items-center">
            <icon-star class="pl-mr-1"></icon-star>
            {{ rating(window.rating) }}
            {{ typePosition(window) }}
          </div>
        </div>
      </div>

      <div
        v-if="window.employer_id === windowsStore.selectedMaster?.employer_id"
        class="pl-unit-active__icon pl-rounded-[50%] pl-w-[18px] pl-h-[18px] pl-bg-black pl-flex pl-items-center pl-justify-center"
      >
        <icon-check-mark></icon-check-mark>
      </div>
    </div>

    <div
      v-if="index + 1 !== windowsStore.filteredMasters.length"
      class="pl-h-[1px] pl-bg-gray pl-mt-3"
    ></div>
  </div>
</template>

<style lang="scss" scoped>

</style>
