import { HttpClient } from '@/tools/http-client'
import type { City } from '@/static-data/city'

export class CitiesApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public citiesList = async (): Promise<City[]> => {
    return this.instance.get('/api/external/v1/cities')
  }
}
