import url from 'query-string'
import type { ParsedQuery } from 'query-string'

export class UrlParse {
  private readonly query: ParsedQuery

  constructor() {
    this.query = url.parse(window.location.search)
  }

  hasUtm(): boolean {
    return this.hasParam('utm_source') || this.hasParam('utm_medium') || this.hasParam('utm_campaign');
  }

  getParam(key: string, fallback: string | null = null): any {
    return this.query[key] || fallback;
  }

  hasParam(key: string): boolean {
    return !!this.getParam(key);
  }

  getUtmSource(): any {
    return this.getParam('utm_source', 'undefined');
  }

  getUtmMedium(): any {
    return this.getParam('utm_medium', 'undefined');
  }

  getUtmCampaign(): any {
    return this.getParam('utm_campaign', 'undefined');
  }
}
