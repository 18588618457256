import type { AxiosInstance } from 'axios'
import type { AxiosRequestConfig } from 'axios'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { useStore } from '@/state/store'
import { useCitiesStore } from '@/state/cities'
import { metrikaGoal } from '@/tools/metrika'
import * as Sentry from '@sentry/vue'

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance
  private readonly store: any
  private readonly citiesStore: any

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    })
    this.store = useStore()
    this.citiesStore = useCitiesStore()

    this._initializeResponseInterceptor()
    this._initializeRequestInterceptor()
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError)
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._handleRequest)
  }

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers["Accept"] = "application/json"
      // config.headers["Content-Type"] = "application/json"
      config.headers["X-Requested-With"] = "XMLHttpRequest"
      config.headers["X-Authorization"] = this.store.apiKey
      config.headers["X-Timezone"] = this.citiesStore.currentCity?.timezone
    }

    return config
  }

  private _handleResponse = ({ data, config }: AxiosResponse) => {
    return data
  }

  protected _handleError = async (error: any) => {
    if (import.meta.env.PROD) {
      metrikaGoal('new_widget_error_api')
      Sentry.captureException(error.response.data?.message)
    }

    return Promise.reject(error)
  }
}
