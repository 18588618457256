import { ServicesApi } from '@/service/services'
import type { getServiceWithPriceParams, Service } from '@/static-data/service'
import type { City } from '@/static-data/city'

export const useServicesMiddleware = () => {
  const servicesApi = new ServicesApi()

  const loadServices = async (cityId: City['id']) => {
    return servicesApi.fetchServices(cityId)
    // const res = await servicesApi.fetchServices(cityId)
    //
    // res.forEach((serviceCategory) => {
    //   serviceCategory.services = serviceCategory.services.filter((localService) => localService.price)
    // })
    //
    // return res.filter((serviceCategory) => serviceCategory.services.length)
  }

  const getServiceWithPrice = async (params: getServiceWithPriceParams) => servicesApi.getServiceWithPrice(params)

  return {
    loadServices,
    getServiceWithPrice
  }
}
