<script lang="ts" setup>
import { onMounted } from 'vue'
import 'v-calendar/dist/style.css';
import { useDateStore } from '@/state/date'
import { isDesktop } from '@/tools/isDesctop'

const dateStore = useDateStore()

onMounted(() => {
  if (!isDesktop()) {
    document.getElementById('v-date-picker')?.scrollIntoView({behavior: 'smooth'})
  }
})
</script>

<template>
  <v-date-picker
    id="v-date-picker"
    :modelValue="dateStore.date"
    is-expanded
    color="black"
    :min-date='new Date()'
    @dayclick="dateStore.setDate"
  />
</template>

<style lang="scss">
@import 'v-calendar/dist/style.css';

.vc-highlight {
  --rounded-full: 5px;
}

.vc-container {
  border: 1px solid #86888a;
}
</style>
