<script lang="ts" setup>
import { inject, ref } from 'vue'
import { useUnitsStore } from '@/state/units'
import IconLocation from '@/components/icons/icon-location.vue'
import IconCard from '@/components/icons/icon-card.vue'
import IconCheckMark from '@/components/icons/icon-check-mark.vue'
import InfoBlock from '@/components/info-block.vue'
import Modal from '@/components/modal.vue'
import PlButton from '@/components/pl-button.vue'

defineProps<{
  showModal: boolean
}>()

const unitsStore = useUnitsStore()
const changeStep = inject('change-step')
const isModalPrevStepShow = ref(false)

const changeStepLocal = () => {
  isModalPrevStepShow.value = false
  changeStep(0)
}
</script>

<template>
  <info-block
    class-list="pl-py-2 pl-mt-5 pl-min-h-[80px]"
    @click="isModalPrevStepShow = !isModalPrevStepShow"
  >
    <div class="pl-grid pl-grid-cols-[1fr_18px] pl-cursor-pointer pl-unit-active">
      <div class="pl-text-sm">
        <div class="pl-flex pl-items-center pl-justify-start">
          <icon-location class="pl-mr-2"></icon-location>
          {{ unitsStore.selectedUnit.name }}
        </div>
        <div class="pl-flex pl-items-center pl-justify-start">
          <icon-card class="pl-mr-2"></icon-card>
          {{ unitsStore.selectedUnit.payment_message }}
        </div>
      </div>

      <div class="pl-self-center pl-unit-active__icon pl-rounded-[50%] pl-w-[18px] pl-h-[18px] pl-bg-black pl-flex pl-items-center pl-justify-center">
        <icon-check-mark></icon-check-mark>
      </div>
    </div>
  </info-block>

  <modal
    v-model="isModalPrevStepShow"
  >
    <div class="pl-font-semibold">Выбранные студии</div>
    <div class="pl-mt-3">
      Изменить студию можно только <br> на первом шаге
    </div>

    <pl-button
      label="Изменить"
      @click="changeStepLocal(0)"
    />

    <button
      class="pl-bg-transparent pl-border-[1px] pl-border-solid pl-border-black pl-rounded-[2rem] pl-py-[8px] pl-w-full pl-mt-3"
      @click="isModalPrevStepShow = false"
    >
      Закрыть
    </button>
  </modal>
</template>

<style lang="scss" scoped>

</style>
