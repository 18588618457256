export const standardWidget = {
  steps: [
    {
      desktop: {
        firstColumn: {
          components: ['select-master-category', 'four-hands', 'services-list', 'cancel-booking'],
          columnClasses: [],
          componentsClasses: {
            'select-master-category': ['some-classes'],
            'services-list': ['pl-mt-5']
          },
          componentsSetting: {
            'cancel-booking': {
              position: 'right'
            },
            'services-list': {
              closeServices: [64, 71],
              hiddenServiceGroup: [63]
            }
          }
        },
        secondColumn: {
          components: ['units-map'],
          columnClasses: ['pl-col-span-2'],
          componentsClasses: {
            'units-map': ['pl-mt-5']
          },
          componentsSetting: {
            'units-map': {
              checkIfNeedResetState: true,
              isShowChangeCityBtn: true
            }
          }
        }
      },
      mobile: {
        firstColumn: {
          components: ['select-master-category', 'four-hands', 'services-list', 'toggle-map-or-list', 'to-second-step', 'cancel-booking'],
          columnClasses: [],
          componentsClasses: {
            'select-master-category': ['some-classes'],
            'services-list': ['pl-mt-5'],
            'toggle-map-or-list': ['pl-mt-5'],
            'cancel-booking': ['pl-my-5']
          },
          componentsSetting: {
            'services-list': {
              closeServices: [16, 11, 63, 36],
              hiddenServiceGroup: [63]
            },
            'toggle-map-or-list': {
              checkIfNeedResetState: true,
              isShowChangeCityBtn: true
            }
          }
        }
      }
    },
    {
      desktop: {
        firstColumn: {
          components: ['select-master-category', 'four-hands', 'selected-unit', 'selected-services'],
          columnClasses: [],
          componentsClasses: {
            'selected-services': 'pl-mt-5',
            'select-services': 'some-classes'
          },
          componentsSetting: {
            'four-hands': {
              disable: true,
              showModalChangeStep: true
            }
          }
        },
        secondColumn: {
          components: ['date-picker', 'select-time'],
          columnClasses: [],
          componentsClasses: {
            'date-picker': 'pl-mt-5',
            'select-time': 'pl-mt-5',
          },
          componentsSetting: {}
        },
        thirdColumn: {
          components: ['select-master', 'order-sum', 'to-create-booking-btn'],
          columnClasses: [],
          componentsClasses: {
            'select-master': 'pl-mt-5',
            'order-sum': 'pl-mt-5',
            'to-create-booking-btn': 'pl-mt-5'
          },
          componentsSetting: {}
        },
      },
      mobile: {
        firstColumn: {
          components: ['select-master-category', 'selected-unit', 'four-hands', 'selected-services', 'date-picker', 'select-time', 'select-master', 'order-sum', 'to-create-booking-btn'],
          columnClasses: [],
          componentsClasses: {
            'select-master-category': ['some-classes'],
            'services-list': ['pl-mt-5'],
            'toggle-map-or-list': ['pl-mt-5'],
            'to-second-step': ['pl-mb-5'],
            'selected-services': ['pl-mt-5'],
            'date-picker': ['pl-mt-5'],
            'select-time': ['pl-mt-5'],
            'select-master': ['pl-mt-5'],
            'to-create-booking-btn': ['pl-mt-5'],

          },
          componentsSetting: {
            'four-hands': {
              disable: true,
              showModalChangeStep: true
            }
          }
        }
      }
    },
    {
      desktop: {
        firstColumn: {
          components: ['auth-user-create-booking'],
          columnClasses: ['pl-col-span-3'],
          componentsClasses: {
            'auth-user-create-booking': 'pl-mx-auto pl-max-w-[400px]',
          },
          componentsSetting: {
            'auth-user-create-booking': {
              addReview: true,
              phone: {
                customPhone: true
              }
            }
          }
        }
      },
      mobile: {
        firstColumn: {
          components: ['auth-user-create-booking'],
          columnClasses: [],
          componentsClasses: {
            'select-master-category': ['some-classes'],
            'services-list': ['pl-mt-5'],
            'toggle-map-or-list': ['pl-mt-5'],
            'to-second-step': ['pl-mb-5']
          },
          componentsSetting: {
            'auth-user-create-booking': {
              addReview: true
            }
          }
        }
      }
    },
  ],
  containerMaxWidth: {
    mobile: '425px',
    desktop: '1280px'
  },
  map: {
    filter: 'grayscale(100%)'
  },
  colors: {

  },
  // isMasterSecondNameHidden: true,
  disableGroup: []
}

export const DEFAULT_VIEW = 'standardWidget'

export const VIEW_SETTINGS = {
  standardWidget
}
