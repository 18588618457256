<script lang="ts" setup>
import { computed, ref, defineEmits, inject, onMounted } from 'vue'
import PlCheckbox from '@/components/pl-checkbox.vue'
import { vMaska } from 'maska'
import {PhoneApi} from '@/service/phone'
import {useUserMiddleware} from '@/middleware/user-middleware'
import PlButton from '@/components/pl-button.vue'
import { metrikaGoal } from '@/tools/metrika'
import { useBookingMiddleware } from '@/middleware/booking-middleware'

const AVAILABLE_COUNTRIES = ['BY', 'UZ', 'RU']

enum CurrentIso {
  RU = 'RU',
  BY = 'BY',
  UZ = 'UZ'
}

const props = defineProps<{
  emitAuthEvent?: boolean
  settings: Record<string, any>
}>()

const phoneApi = new PhoneApi()
const userMiddleware = useUserMiddleware()
const phone = ref('')
const acceptRules = ref(false)
const code = ref([])
const waitCode = ref(false)
const emit = defineEmits(['auth-user'])
const isShowCancelBookingModal = inject('isShowCancelBookingModal')
const closeCancelBookingModal = inject('closeCancelBookingModal')
const seconds = ref(60)
const interval = ref(null)
const globalEvents = inject('global-events')
const bookingMiddleware = useBookingMiddleware()
const errorMsg = ref('')
const currentIso = ref<keyof typeof CurrentIso | null>(null)
const isPhoneValid = ref(false)

const INVALID_LOGON_CODE = 1002

const focusEl = async (event, index) => {
  if (code.value[index].toString().length > 1) {
    const codeStr = code.value[index].toString()
    code.value[index] = parseInt(codeStr[codeStr.length - 1]) ?? ''
  }
  if (!code.value[index] && code.value[index] !== 0) {
    event.target.previousElementSibling.focus();
    return
  }
  if (index < 3) {
    event.target.nextElementSibling.focus();
    return
  }

  try {
    const user = await userMiddleware.authAndGetUser(code.value.join(''), phone.value)

    if (user.is_new_user) {
      metrikaGoal('new_user_reg')
    }

    metrikaGoal('four_number_code_enter')

    if (props.emitAuthEvent) {
      emit('auth-user')
    } else {
      await bookingMiddleware.createBooking()
      emit('auth-user')
    }
  } catch (e) {
    if (e.response?.data?.code === INVALID_LOGON_CODE) {
      showTemporaryError('Не верный код')
      return
    }

    showTemporaryError('Не удалось авторизоваться')
  }
}

const showTemporaryError = (errorValue: string) => {
  errorMsg.value = errorValue

  setTimeout(() => {
    errorMsg.value = ''
  }, 10000)
}



const getCode = async () => {
  try {
    await phoneApi.getCodeByPhone(`+${phone.value.replace(/[^0-9]/g, '')}`)
    waitCode.value = true
    startTimer()
  } catch (e) {
    showTemporaryError('Ошибка при получении кода, попробуйте через несколько минут')
  }
}

const startTimer = () => {
  interval.value = setInterval(() => {
    seconds.value -= 1

    if (!seconds.value) {
      clearInterval(interval.value)
      interval.value = null
      seconds.value = 60
    }
  }, 1000)
}

const isVueTelPhoneShow = computed(() => props.settings?.phone?.customPhone)
const countryChanged = (data: {
  iso2: keyof typeof CurrentIso
  dialCode: string
  name: string
}) => {
  currentIso.value = data.iso2
  phone.value = `+${data.dialCode}`
}
</script>

<template>
  <div class="pl-text-center pl-text-sm pl-mt-[60px]">
    Вам поступит звонок-сброс, введите последние 4 цифры входящего номера в поле ниже:
  </div>

<!--  v-if="isVueTelPhoneShow"-->
  <phone-input
    v-model="phone"
    @validate="newValue => isPhoneValid = newValue.valid"
  />

<!--  <vue-tel-input-->
<!--    v-model="phone"-->
<!--    :onlyCountries="AVAILABLE_COUNTRIES"-->
<!--    mode="international"-->
<!--    :inputOptions="{-->
<!--      placeholder: 'Введите телефон'-->
<!--    }"-->
<!--    class="tw-max-w-[100px]"-->
<!--    validCharactersOnly-->
<!--    @country-changed="countryChanged"-->
<!--  />-->

<!--  <input-->
<!--    v-if="!isVueTelPhoneShow"-->
<!--    v-model="phone"-->
<!--    v-maska-->
<!--    data-maska="+7 (###) ###-##-##"-->
<!--    placeholder="+7 (___) ___-__-__"-->
<!--    type="text"-->
<!--    class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-my-3 pl-m-auto pl-w-[200px] pl-block pl-p-1"-->
<!--  />-->

  <pl-checkbox v-model="acceptRules">
    <div class="pl-text-black pl-text-sm">
      Я подтверждаю согласие на использование сведений обо мне (хранение и обработка персональных данных) и получение рекламы в виде SMS и e-mail рассылки.
    </div>
  </pl-checkbox>

  <div
    class="pl-text-center"
    v-if="waitCode"
  >
    Введите поcледние 4 цифры
    <div class="pl-flex pl-justify-center">
      <input
        v-for="n in 4"
        :key="n"
        ref="focus"
        v-model="code[n - 1]"
        data-maska="#"
        type="text"
        inputmode="numeric"
        @input="focusEl($event, n - 1)"
        class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-w-[40px] pl-h-[60px] pl-mx-[10px] pl-mt-5 pl-text-[30px] pl-text-center"
      />
    </div>
  </div>

  <div
    v-if="errorMsg"
    class="pl-text-center pl-text-sm pl-mt-3 pl-font-bold"
  >
    {{ errorMsg }}
  </div>

  <div v-if="interval" class="pl-text-center pl-text-sm pl-mt-5">
    Запросить код повторно можно через {{ seconds }} секунд
  </div>

  <pl-button
    label="Получить код"
    :disable="!acceptRules || interval || !isPhoneValid"
    @click="getCode"
  />
</template>

<style lang="scss" scoped>

</style>
