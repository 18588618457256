import { defineStore } from 'pinia'
import { reactive, ref, computed } from 'vue'
import { useWindowsMiddleware } from '@/middleware/windows'
import type { Window } from '@/static-data/window'
import { TYPE_PRICE_CATEGORY, TYPE_PRICE_CATEGORY_NUMBER } from '@/static-data/window'
import { DateTime } from 'luxon'
import { useBookingMiddleware } from '@/middleware/booking-middleware'
import { useServicesStore } from '@/state/services'
import { useServicesMiddleware } from '@/middleware/services'
import type { Service } from '@/static-data/service'
import { useUnitsStore } from '@/state/units'
import { useCitiesStore } from '@/state/cities'
import { SERVICE_GROUP_MANICURE_ID } from '@/static-data/service'
import { useBookingStore } from '@/state/booking'
import type { CheckPromoResponse } from '@/static-data/booking'

const ID_SERVICE_PARALLEL = 107

export const useWindowsStore = defineStore('windows-store', () => {
  const windowsOrSchedules = reactive({ type: null, windows: [] })
  const windowsMiddleware = useWindowsMiddleware()
  const bookingMiddleware = useBookingMiddleware()
  const selectedTime = ref(null)
  const selectedMaster = ref<Window | null>(null)
  const filterBy = ref(null)
  const searchMasterStr = ref('')
  const loading = ref(false)
  const sumForPromo = ref(0)
  const promoCode = ref<string | null>(null)
  const parallel = ref(false)
  const selectedWindowParallel = ref(null)
  const serviceStore = useServicesStore()
  const servicesMiddleware = useServicesMiddleware()
  const additionalPriceWithFourHands = ref(0)
  const unitsStore = useUnitsStore()
  const citiesStore = useCitiesStore()
  const bookingStore = useBookingStore()
  const orderDescription = ref('')
  const currentCompanyId = ref<number | null>(null)

  const resetMasterAndTime = () => {
    selectedTime.value = null
    selectedMaster.value = null
    filterBy.value = null
  }

  const fourHands = computed(() => windowsOrSchedules.windows[0]?.length > 1)

  const setSelectedWindowParallel = async (windows) => {
    selectedWindowParallel.value = windows
    await checkAdditionalPriceByFourHands()
    setOrderDescription()
  }

  const getSelectedWindowOrScheduleFullObj = computed(() => selectedWindowParallel.value)

  const getSelectedWindowOrSchedule = computed(() => {
    if (fourHands.value) {
      return selectedWindowParallel.value?.windows
    }

    let result = null

    if (!selectedMaster.value || !selectedTime.value) {
      return
    }

    windowsOrSchedules.windows.forEach((window) => {
      window.forEach((localWindow) => {
        if (selectedMaster.value.employer_id === localWindow.employer_id && selectedTime.value.begin_at === localWindow.begin_at) {
          result = window
        }
      })
    })

    return result
  })

  const getMapSelectedService = computed(() => {
    const servicesList = []
    getSelectedWindowOrSchedule.value.forEach((windowOrSchedule) => {
      windowOrSchedule.services.forEach((item) => {
        servicesList.push({
          id: item.id,
          price: item.price,
          count: 1
        })
      })
    });

    return servicesList
  })

  const loadWindows = async () => {
    if (loading.value) {
      return
    }

    loading.value = true
    const { windows, schedules } = await windowsMiddleware.loadWindows()

    windowsOrSchedules.type = windows.length ? 'windows' : 'schedules'
    windowsOrSchedules.windows = windows.length ? windows : schedules
    loading.value = false
  }

  const setSearchMasterStr = (newValue) => searchMasterStr.value = newValue

  const setSelectedMaster = async (newMaster) => {
    if (!filterBy.value) {
      filterBy.value = 'master'
    }

    if (selectedMaster.value?.id === newMaster.id) {
      selectedMaster.value = null

      if (filterBy.value === 'master') {
        filterBy.value = null
      }

      return
    }

    if (selectedTime.value && filterBy.value === 'master') {
      selectedTime.value = null
    }

    selectedMaster.value = newMaster

    await checkAdditionalPriceByFourHands()
    setOrderDescription()
    setCompanyId()
  }

  const setSelectedTime = async (newTime) => {
    if (!filterBy.value) {
      filterBy.value = 'time'
    }

    if (selectedTime.value?.begin_at === newTime.begin_at) {
      selectedTime.value = null
      filterBy.value = null
      return
    }

    if (selectedMaster.value && filterBy.value === 'time') {
      selectedMaster.value = null
    }

    selectedTime.value = newTime
    await checkAdditionalPriceByFourHands()
    setCompanyId()
  }

  const filteredMasters = computed(() => {
    const windowsOrSchedulesWithUniqKey: Record<number, Window> = windowsOrSchedules.windows.reduce((result: Record<number, Window>, window: Window[]) => {
      if (fourHands.value) {
        const sortedWindow = window.sort((localWindow, localWindowSecond) => localWindow.employer_id - localWindowSecond.employer_id)
        const uniqId = `${sortedWindow[0].employer_id}_${sortedWindow[1].employer_id}`
        const firstWindow = sortedWindow[0]
        const secondWindow = sortedWindow[1]

        if (result[uniqId]) {
          const startServiceTime = DateTime.fromSeconds(firstWindow.begin_at).toFormat('HH:mm')
          const isTimeExist = result[uniqId].times.findIndex((item) => item.time === startServiceTime) > -1

          if (!isTimeExist) {
            result[uniqId].times.push({
              time: startServiceTime,
              windows: window
            })
          }

          return result
        }

        result[uniqId] = {
          windowsByMasters: window,
          times: [{
            time: DateTime.fromSeconds(firstWindow.begin_at).toFormat('HH:mm'),
            windows: window
          }],
          totalRating: Number(firstWindow.rating) + Number(secondWindow.rating)
        }

        return result
      }

      window.forEach((item) => {
        if (searchMasterStr.value) {
          if (item.first_name?.toLowerCase().includes(searchMasterStr.value.toLowerCase())
            || item.middle_name?.toLowerCase().includes(searchMasterStr.value.toLowerCase())
            || item.last_name?.toLowerCase().includes(searchMasterStr.value.toLowerCase())) {
            if (filterBy.value === 'time' && item.employer_id === selectedTime.value.employer_id) {
              result[item.employer_id] = item
            }

            if (filterBy.value !== 'time') {
              result[item.employer_id] = item
            }
          }
        } else {
          if (filterBy.value === 'time' && item.begin_at === selectedTime.value.begin_at) {
            result[item.employer_id] = item
          }

          if (filterBy.value !== 'time') {
            result[item.employer_id] = item
          }
        }
      })
      return result
    }, {})

    if (fourHands.value) {
      return Object.values(windowsOrSchedulesWithUniqKey).sort((prev: Window, next: Window) => Number(next.totalRating) - Number(prev.totalRating))
    }

    return Object.values(windowsOrSchedulesWithUniqKey).sort((prev: Window, next: Window) => Number(next.rating) - Number(prev.rating))
  })

  const filteredTimes = computed(() => {
    let windowsOrSchedulesWithUniqKey = windowsOrSchedules.windows.reduce((result, window: Window[]) => {
      window.forEach((item) => {
        if (filterBy.value === 'master' && item.employer_id === selectedMaster.value.employer_id) {
          result[item.begin_at] = {
            renderId: `select-time-${Math.random()}`,
            ...item,
            formatBeginAt: DateTime.fromFormat(item.begin_h, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm')
          }
        }
        if (filterBy.value !== 'master') {
          result[item.begin_at] = {
            renderId: `select-time-${Math.random()}`,
            ...item,
            formatBeginAt: DateTime.fromFormat(item.begin_h, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm')
          }
        }
      })
      return result
    }, {})

    let windows = Object.values(windowsOrSchedulesWithUniqKey)

    if (selectedMaster.value) {
      let isTimeHaveMaster = false

    }

    return windows
  })

  const orderSum = computed(() => {
    let price = 0
    const durationTime = {
      minStartTime: 0,
      maxEndTime: 0
    }

    getSelectedWindowOrSchedule.value.forEach((item) => {
      const { sum } = item.services.reduce((result, service) => {
        result.sum += +service.price

        return result
      }, {
        sum: 0
      })

      if (!durationTime.minStartTime || durationTime.minStartTime > item.begin_at) durationTime.minStartTime = item.begin_at
      if (!durationTime.maxEndTime || durationTime.maxEndTime < item.maxEndTime) durationTime.maxEndTime = item.end_at

      price += sum
    })

    price += additionalPriceWithFourHands.value

    return {
      price: price,
      durationInMin: Math.round((durationTime.maxEndTime - durationTime.minStartTime) / 60)
    }
  })

  const addPromo = async (promoCodeValue: string): Promise<void | Service['id'][]> => {
    const { data } = await bookingMiddleware.sendPromoCode(promoCodeValue, orderSum.value.price)
    promoCode.value = promoCodeValue

    if (data.price) sumForPromo.value = data.price
    if (!data.price) sumForPromo.value = orderSum.value.price

    if (data.gift_services_to_choose?.length) {
      return data.gift_services_to_choose
    }

    bookingStore.giftServicesIds.set(data.gift_service_ids)
  }

  const checkGiftServiceByTopMaster = async (promoCodeValue: string): Promise<void> => {
    const { data } = await bookingMiddleware.checkGiftServiceByTopMaster(promoCodeValue, bookingStore.giftServicesIds.get)
    const totalPrice = data.reduce((result, item) => {
      if (item.price) result += item.price
      return result
    }, 0)

    bookingStore.priceByGiftService.set(totalPrice)
  }

  const addPromoByGiftService = async (promoCodeValue: string, giftServiceId: Service['id']): Promise<void | Service['id'][]> => {
    await bookingMiddleware.sendPromoCodeByGiftService(promoCodeValue, orderSum.value.price, giftServiceId)
    bookingStore.giftServicesIds.set([giftServiceId])
  }

  const setParallel = (newValue: boolean) => parallel.value = newValue

  const isMasterAndTimeSelected = computed(() => (selectedTime.value && selectedMaster.value) || selectedWindowParallel.value)

  const checkAdditionalPriceByFourHands = async () => {
    if (!selectedWindowParallel.value) {
      additionalPriceWithFourHands.value = 0
      return
    }

    let selectedWindow = null

    selectedWindowParallel.value?.windows.forEach((window) => {
      window.services.forEach((service) => {
        if (service.service_category_id === SERVICE_GROUP_MANICURE_ID) {
          selectedWindow = window
        }
      })
    })

    if (!selectedWindow) selectedWindow = selectedWindowParallel.value.windows[0]

    const serviceWithPrice = await servicesMiddleware.getServiceWithPrice({
      serviceId: ID_SERVICE_PARALLEL,
      unitId: unitsStore.selectedUnit.id,
      cityId: citiesStore.cityId
    })

    if (selectedWindow.type_price_group === TYPE_PRICE_CATEGORY.MASTER_NEW) {
      if (parallel.value) additionalPriceWithFourHands.value = parseInt(serviceWithPrice.prices[TYPE_PRICE_CATEGORY_NUMBER.MASTER_NEW].price)
      orderDescription.value = `Мастер ${selectedWindow.first_name} работает менее полугода`
    }

    if (selectedWindow.type_price_group === TYPE_PRICE_CATEGORY.MASTER) {
      if (parallel.value) additionalPriceWithFourHands.value = parseInt(serviceWithPrice.prices[TYPE_PRICE_CATEGORY_NUMBER.MASTER].price)
      orderDescription.value = ''
    }

    if (selectedWindow.type_price_group === TYPE_PRICE_CATEGORY.TOP_MASTER) {
      if (parallel.value) additionalPriceWithFourHands.value = parseInt(serviceWithPrice.prices[TYPE_PRICE_CATEGORY_NUMBER.TOP_MASTER].price)
      orderDescription.value = ''
    }
  }

  const setOrderDescription = () => {
    console.log(selectedMaster.value)
    if (!selectedMaster.value) return

    if (selectedMaster.value.type_price_group === TYPE_PRICE_CATEGORY.MASTER_NEW) {
      orderDescription.value = `Мастер ${selectedMaster.value.first_name} работает менее полугода`
    }

    if (selectedMaster.value.type_price_group === TYPE_PRICE_CATEGORY.MASTER) {
      orderDescription.value = ''
    }

    if (selectedMaster.value.type_price_group === TYPE_PRICE_CATEGORY.TOP_MASTER) {
      orderDescription.value = ''
    }
  }

  const setCompanyId = () => {
    currentCompanyId.value = getSelectedWindowOrSchedule.value[0] ? getSelectedWindowOrSchedule.value[0]?.company_id : null
    console.log('setCompanyId', currentCompanyId.value)
  }

  const resetPromo = () => {
    bookingStore.giftServicesIds.set([])
    sumForPromo.value = 0
    bookingStore.priceByGiftService.set(0)
  }

  return {
    windowsOrSchedules,
    loadWindows,
    setSelectedTime,
    selectedTime,
    selectedMaster,
    setSelectedMaster,
    filteredMasters,
    filteredTimes,
    searchMasterStr,
    setSearchMasterStr,
    getSelectedWindowOrSchedule,
    loading,
    orderSum,
    addPromo,
    sumForPromo,
    promoCode,
    parallel,
    setParallel,
    fourHands,
    setSelectedWindowParallel,
    selectedWindowParallel,
    getSelectedWindowOrScheduleFullObj,
    resetMasterAndTime,
    getMapSelectedService,
    isMasterAndTimeSelected,
    orderDescription,
    currentCompanyId,
    addPromoByGiftService,
    checkGiftServiceByTopMaster,
    resetPromo
  }
})
