<script lang="ts" setup>
import { ref, reactive, watch, nextTick } from 'vue'
import IconCloseNew from '@/components/icons/icon-close-new.vue'
import PlButton from '@/components/pl-button.vue'
import { useBookingStore } from '@/state/booking'
import QuestionTooltip from '@/components/question.vue'

const MAX_COMMENT_LENGTH = 50
const MAX_IMAGE_COUNT = 3

const comment = ref('')
const images = reactive({})
const bookingStore = useBookingStore()

const onchangeFileInput = (event) => {
  const [file] = event.target.files

  if (images[file.name]) {
    delete images[file.name]
    return
  }

  const reader = new FileReader();
  reader.readAsDataURL(file)

  reader.onload = () => {
    images[file.name] = reader.result
    bookingStore.setImages(file, file.name)
  }
}

const deleteFile = (key) => {
  delete images[key]
  bookingStore.removeImage(key)
}

const updateComment = async (event: Event) => {
  bookingStore.setComment(event.target.value)
  comment.value = event.target.value
}
</script>

<template>
  <input
    type="text"
    class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-w-full pl-px-3 pl-h-[35px] pl-mt-5"
    :value="comment"
    placeholder="Комментарий"
    :maxlength="MAX_COMMENT_LENGTH"
    @input="updateComment"
  >
  <div class="pl-text-gray pl-text-sm">
    Максимально {{ MAX_COMMENT_LENGTH }} символов
  </div>

  <div
    v-if="Object.keys(images).length"
    class="pl-flex pl-mt-5"
  >
    <div
      v-for="(value, key) in images"
      :key="key"
      class="pl-relative pl-w-16 pl-h-16 pl-border-1 pl-border-solid pl-border-white pl-rounded-md pl-bg-cover pl-mr-3"
      :style="{ backgroundImage: `url(${value})` }"
    >
      <div
        class="pl-absolute pl-right-[-10px] pl-top-[-10px]"
        @click="deleteFile(key)"
      >
        <icon-close-new />
      </div>
    </div>

    <div
      v-if="Object.keys(images).length === MAX_IMAGE_COUNT"
      class="pl-ml-5 pl-text-green pl-font-semibold">
      Макс. {{ MAX_IMAGE_COUNT }} файла
    </div>
  </div>

  <div
    v-if="Object.keys(images).length !== MAX_IMAGE_COUNT"
    class="pl-grid pl-grid-cols-[150px_1fr] pl-gap-4 pl-mt-5"
  >
    <div class="pl-relative">
      <pl-button
        label="Добавить фото"
        class="pl-block pl-mt-0 !pl-rounded-lg"
      />

      <input
        class="pl-absolute pl-w-full pl-h-full pl-left-0 pl-top-0 pl-opacity-0"
        accept="image/*"
        type="file"
        ref="fileInput"
        multiple="multiple"
        @change="onchangeFileInput"
      />
    </div>

    <div class="pl-text-sm">
      <div class="pl-flex">
        <div>Файл не выбран</div>

        <question-tooltip
          class="pl-ml-2"
        />
      </div>

      <div class="pl-mt-1">Максимальный размер: 2 Mb</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
