<script lang="ts" setup>
import { ref, onMounted, defineEmits, withDefaults, inject, computed } from 'vue'
import type { Booking } from '@/static-data/booking'
import { ConvertDate } from '@/tools/convert-date'
import IconTime from '@/components/icons/icon-time.vue'
import IconPlace from '@/components/icons/icon-place.vue'
import IconCard from '@/components/icons/icon-card.vue'
import IconAccept from '@/components/icons/icon-accept.vue'
import PlButton from '@/components/pl-button.vue'
import AppStore from '@/components/icons/app-store.vue'
import GooglePlay from '@/components/icons/google-play.vue'
import { useGiftService } from '@/composible/giftServices'
import type { GiftServiceTopMaster} from '@/static-data/service'

const globalEvents = inject('global-events')
const changeStep = inject('change-step')
const { giftServiceNamesTemplate } = useGiftService()

const props = withDefaults(defineProps<{
  booking: Booking | Record<string, any>
  giftServices: GiftServiceTopMaster[]
}>(), {
  booking: {}
})

const emits = defineEmits(['close', 'to-main'])
const formatDate = (date: Booking['begin_at']) => new ConvertDate(date).toDayFormatWithTime
const goToMain = ref(5)
const money = () =>
  new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  })
const formatMoney = (amount: number) => money().format(amount)
const totalCoast = computed(() => {
  let totalSum = props.booking?.total
  // if (props.booking?.voucher?.amount) {
  //   return formatMoney(props.booking?.total - props.booking?.voucher?.amount)
  // }

  if (props.giftServices?.length) {
    props.giftServices.forEach((giftService) => {
      if (giftService.price) totalSum += giftService.price
    })
  }

  return formatMoney(totalSum)
})

onMounted(() => {
  if (globalEvents?.successGoal) {
    globalEvents.successGoal()
  }
})
</script>

<template>
  <div class="pl-text-center">
    <icon-accept class="pl-mx-auto pl-mt-5" />
    <div class="pl-font-semibold pl-mt-5">
      Вы успешно записались!
    </div>

    <div>
      <div class="pl-mt-3 pl-flex pl-items-center pl-justify-center">
        <icon-time style="scale: .7" />
        <div class="pl-ml-1">Ожидаем Вас <span class="pl-text-bold">{{ props.booking?.begin_at }}</span></div>
      </div>

      <div class="pl-mt-3 pl-flex pl-items-center pl-justify-center">
        <icon-place style="scale: .7" />
        <div class="pl-ml-1">В студии <span class="pl-text-bold">{{ props.booking?.unit?.name }}</span></div>
      </div>

      <div class="pl-mt-3 pl-flex pl-items-center pl-justify-center">
        <icon-card style="scale: .7" />
        <div class="pl-ml-1">Сумма: <span class="pl-text-bold">{{ totalCoast }}</span></div>
      </div>

      <div class="pl-mt-3 pl-flex pl-items-center pl-justify-center">
        <div class="pl-ml-1"><span class="pl-text-bold">{{ props.booking?.unit?.cashless_pay ? 'Студия принимает все виды оплат' : 'Студия принимает оплату только наличными' }}</span></div>
      </div>

      <div v-if="giftServiceNamesTemplate" class="pl-mt-3 pl-flex pl-items-center pl-justify-center">
        <div v-html="giftServiceNamesTemplate" class="pl-ml-1"></div>
      </div>

      <div class="pl-font-semibold pl-mt-5">
        Вы можете записываться или редактировать запись через наше приложение
      </div>

      <div class="pl-mt-5 pl-flex pl-items-center pl-justify-center">
        <a
          href="https://apps.apple.com/ru/app/%D0%BF%D0%B8%D0%BB%D0%BA%D0%B8-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0/id1457078824"
          target="_blank"
          class="hover:opacity-80 pl-mr-5"
        >
         <app-store />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=pilki.tech.aggregator.client"
          target="_blank"
          class="hover:opacity-80"
        >
          <google-play />
        </a>
      </div>

      <pl-button
        label="На главную"
        class="pl-mt-10 pl-max-w-[300px]"
        @click="changeStep(0)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
