<script lang="ts" setup>
import { computed } from 'vue'
import type { Unit } from '@/static-data/unit'
import { useStore } from '@/state/store'
import IconCard from "@/components/icons/icon-card.vue";
import IconCheckMark from "@/components/icons/icon-check-mark.vue";
import IconLocation from "@/components/icons/icon-location.vue";
import { useUnitDescription } from '@/hooks/unit-description'

const props = defineProps<{ unit: Unit }>()
const { unitClass } = useUnitDescription(props)
</script>

<template>
  <div :class="unitClass" class="pl-grid pl-grid-cols-[1fr_18px] pl-cursor-pointer">
    <div class="pl-text-sm">
      <div class="pl-flex pl-items-center pl-justify-start">
        <icon-location class="pl-mr-2"></icon-location>
        {{ unit.name }}
      </div>
      <div class="pl-flex pl-items-center pl-justify-start">
        <icon-card class="pl-mr-2"></icon-card>
        {{ unit.cashless_pay ? 'Студия принимает все виды оплат' : 'Студия принимает оплату только наличными'  }}
      </div>
    </div>

    <div class="pl-self-center pl-unit-active__icon pl-rounded-[50%] pl-w-[18px] pl-h-[18px] pl-bg-black pl-flex pl-items-center pl-justify-center">
      <icon-check-mark></icon-check-mark>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pl-unit-active__icon {
  display: none;
}
.pl-unit-active .pl-unit-active__icon {
  display: flex;
}
</style>
