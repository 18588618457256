import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useWindowsStore } from '@/state/windows'

export const useEmployerCategoryGroup = defineStore('employer-category-group-store', () => {
  const windowsStore = useWindowsStore()
  const employerCategoryGroup = [
    {
      id: 1,
      name: 'Мастер',
    },
    {
      id: 2,
      name: 'Мастер'
    },
    {
      id: 3,
      name: 'Топ-Мастер'
    },
  ]
  const selectedEmployerCategoryGroup = ref(employerCategoryGroup.map((item) => item.id))

  const toggleEmployerCategoryGroup = (groupId) => {
    const indexNewGroup = selectedEmployerCategoryGroup.value.findIndex((item) => item === groupId)

    if (indexNewGroup > -1) {
      selectedEmployerCategoryGroup.value.splice(indexNewGroup, 1)
      windowsStore.loadWindows()
      return
    }

    selectedEmployerCategoryGroup.value.push(groupId)
    windowsStore.loadWindows()
  }

  const resetValues = () => selectedEmployerCategoryGroup.value = []

  return {
    employerCategoryGroup,
    toggleEmployerCategoryGroup,
    selectedEmployerCategoryGroup,
    resetValues
  }
})
