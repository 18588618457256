<script lang="ts" setup>
import { onMounted } from 'vue'
import PlButton from '@/components/pl-button.vue'
import IconAccept from '@/components/icons/icon-accept.vue'
import AppStore from '@/components/icons/app-store.vue'
import GooglePlay from '@/components/icons/google-play.vue'

const emits = defineEmits(['close-modal'])

onMounted(() => {
  let timerCount = 5
  setInterval(() => {
    --timerCount

    if (!timerCount) {
      emits('close-modal')
    }
  }, 1000)
})
</script>

<template>
  <div class="pl-text-center">
    <icon-accept class="pl-mx-auto pl-mt-5" />
    <div class="pl-font-semibold pl-mt-5">
      Ваша запись отменена
    </div>

    <div>
      <div class="pl-mt-5 pl-flex pl-items-center pl-justify-center">
        <a
          href="https://apps.apple.com/ru/app/%D0%BF%D0%B8%D0%BB%D0%BA%D0%B8-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0/id1457078824"
          target="_blank"
          class="hover:opacity-80 pl-mr-5"
        >
          <app-store />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=pilki.tech.aggregator.client"
          target="_blank"
          class="hover:opacity-80"
        >
          <google-play />
        </a>
      </div>

      <pl-button
        label="На главную"
        class="pl-mt-10 pl-max-w-[300px]"
        @click="emits('close-modal')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
