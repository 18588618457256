<script lang="ts" setup>
import { useWindowsHook } from '@/hooks/load-windows'
import InfoBlock from '@/components/info-block.vue'
import { useWindowsStore } from '@/state/windows'
import Spiner from '@/components/spinner.vue'

const windowsHook = useWindowsHook()
const windowsStore = useWindowsStore()

</script>

<template>
  <info-block
    v-if="!windowsStore.fourHands"
    title="Выберите время"
    max-height="auto"
  >
    <spiner
      v-if="windowsStore.loading"
      class="pl-m-auto pl-w-8 pl-my-10"
    />

    <div v-if="!windowsStore.filteredTimes.length" class="pl-text-center pl-my-5">
      Нет доступного времени
    </div>


    <div
      v-if="!windowsStore.loading"
      class="pl-flex pl-flex-wrap pl-items-center"
    >
      <div
        v-for="window in windowsStore.filteredTimes"
        :key="window.renderId"
        :data-renderId="window.renderId"
        :class="windowsStore.selectedTime?.begin_at === window.begin_at ? 'pl-bg-black pl-text-white' : ''"
        class="pl-text-sm pl-border-[1px] pl-border-solid pl-border-black pl-rounded-md pl-mb-2 pl-mr-1 pl-w-[60px] pl-h-[30px] pl-items-center pl-justify-center pl-flex hover:pl-text-hover-gray pl-cursor-pointer"
        @click="windowsStore.setSelectedTime(window)"
      >
        {{ window.formatBeginAt }}
      </div>
    </div>
  </info-block>
</template>

<style lang="scss" scoped>

</style>
