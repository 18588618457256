import { ref } from 'vue'
import { useCitiesMiddleware } from '@/middleware/cities'
import { DEFAULT_CITY_ID } from '@/static-data/store/city'
import type { City } from '@/static-data/store/city'
import { defineStore } from 'pinia'

export const useCitiesStore = defineStore('cities-store', () => {
  const citiesMiddleware = useCitiesMiddleware()
  const cityId = ref(DEFAULT_CITY_ID)
  const currentCity = ref<City | null>(null)
  const cities = ref<City[]>([])

  const setCurrentCity = async (newCityId: string | number) => {
    cityId.value = Number(newCityId)

    if (!cities.value.length) {
      cities.value = await citiesMiddleware.getCitiesList()
    }

    const newCurrentCity = cities.value.find((city: City) => city.id === Number(cityId.value))

    if (newCurrentCity) {
      currentCity.value = newCurrentCity
    }
  }

  return {
    setCurrentCity,
    cityId,
    currentCity
  }
})
