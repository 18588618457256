import { HttpClient } from '@/tools/http-client'

export class PhoneApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public getCodeByPhone = async (phone: string): Promise<any> => {
    return this.instance.post('/api/external/v1/auth/get_code', {
      phone: `+${phone.replaceAll(/\D/gi, '')}`
    })
  }
}
