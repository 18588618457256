import { MAP_NS } from '@/static-data/map'

const isMapYaExist = () => {
  const scripts = document.getElementsByTagName('script')
  let result

  for (const item of scripts) {
    if (item.src.includes('api-maps.yandex.ru')) {
      result = true
    }
  }

  return result
}

export const createMap = (callBack: () => void) => {

  // if (isMapYaExist() || window?.ymaps || document?.getElementsByTagName('ymaps')?.length) {
  //   callBack()
  //   return
  // }

  const yandexMapScript = document.createElement('script')

  const coordorder = 'latlong'
  const apiKey = '26d989de-9503-4650-bc42-2bfad0128a78'
  const lang = 'ru_RU'
  const version = '2.1'

  const settingsPart = `lang=${lang}&apikey=${apiKey}&mode=release&coordorder=${coordorder}&ns=${MAP_NS}`;
  const link = `https://api-maps.yandex.ru/${version}/?${settingsPart}`;
  yandexMapScript.setAttribute('src', link);
  yandexMapScript.setAttribute('async', '');
  yandexMapScript.setAttribute('defer', '');
  yandexMapScript.setAttribute('id', 'pilki-widget-map');
  document.head.appendChild(yandexMapScript);

  yandexMapScript.onload = () => {
    window[MAP_NS].ready(() => {
      if (callBack) callBack()
    });
  };
}
