<script lang="ts" setup>
import { inject, computed } from 'vue'
import PlButton from '@/components/pl-button.vue'
import { useWindowsStore } from '@/state/windows'

const windowsStore = useWindowsStore()
const nextStep = inject('next-step')
const isBtnNextStepDisable = computed(() => (!windowsStore.selectedTime || !windowsStore.selectedMaster) && !windowsStore.selectedWindowParallel)
</script>

<template>
  <pl-button
    :disable="isBtnNextStepDisable"
    label="Записаться"
    @click="nextStep"
  />
</template>

<style lang="scss" scoped>

</style>
