import { defineStore } from 'pinia'
import { ref } from 'vue'
import { DateTime } from 'luxon'
import { useWindowsStore } from '@/state/windows'

export const useDateStore = defineStore('date-store', () => {
  const date = ref(DateTime.local())
  const windowsStore = useWindowsStore()

  const setDate = (newDate: Record<string, any>) => {
    windowsStore.resetMasterAndTime()
    date.value = DateTime.fromFormat(newDate.id, 'yyyy-MM-dd')
  }

  return {
    date,
    setDate
  }
})
