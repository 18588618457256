<script lang="ts" setup>
import { computed } from 'vue'
import type { Service } from '@/static-data/service'
import { withDefaults } from 'vue'
import PlCheckbox from '@/components/pl-checkbox.vue'
import IconArrowRight from "@/components/icons/icon-arrow-right.vue";
import DesignDescription from '@/components/design-description.vue'
import { useServiceBlock } from '@/hooks/service-block'

const props = withDefaults(defineProps<{
  title: string
  services: Service[]
  maxHeight?: number | string
  defaultHidden: boolean
  serviceId: Service['id']
  settings: Record<string, any>
}>(), {
  maxHeight: 300,
  defaultHidden: false
})

const isServiceShow = computed(() => {
  if (!props.settings || !props.settings.hiddenServiceGroup) return true
  return !props.settings.hiddenServiceGroup.includes(props.serviceId)
})

const {
  showTooltip,
  descriptionRef,
  isBlockHidden,
  showTooltipObj,
  servicesStore,
  checkIfNeedDisable
} = useServiceBlock(props)

</script>

<template>
  <div
    v-if="isServiceShow"
    class="pl-flex pl-items-center pl-justify-between pl-cursor-pointer"
    @click="isBlockHidden = !isBlockHidden"
  >
    {{ title }}
    <icon-arrow-right
        class="pl-transition-all"
        :class="isBlockHidden ? '' : 'pl-rotate-90'"
    >

    </icon-arrow-right>
  </div>

  <div
    v-if="isServiceShow"
    class="pl-px-2 pl-transition-all pl-mt-2"
    :class="isBlockHidden ? 'pl-h-0 pl-overflow-hidden' : `pl-max-h-[${maxHeight}px] pl-overflow-auto`"
  >
    <pl-checkbox
      v-for="(serviceForSelect, index) in services"
      :key="serviceForSelect.id"
      :class="index + 1 === services.length ? 'pl-mb-2' : ''"
      :modelValue="!!servicesStore.selectedServices[serviceForSelect.id]"
      class="pl-mt-2"
      :disabled="checkIfNeedDisable(serviceForSelect)"
      @update:modelValue="servicesStore.addOrDeleteSelectedService(serviceForSelect)"
    >
      {{ serviceForSelect.title }}

      <template #desc>
        <div
          v-if="showTooltip(serviceForSelect)"
          :id="`select-service-${showTooltip(serviceForSelect)}`"
          class="pl-ml-5 pl-rounded-full pl-bg-black pl-w-[18px] pl-h-[18px] pl-text-white pl-flex pl-items-center pl-justify-center"
          @click="showTooltipObj[showTooltip(serviceForSelect)] = true"
        >
          ?
        </div>

        <design-description
          v-if="showTooltipObj[showTooltip(serviceForSelect)]"
          :price="showTooltip(serviceForSelect)"
          ref="descriptionRef"
        />
      </template>
    </pl-checkbox>
  </div>
</template>

<style lang="scss" scoped>

</style>
