import { createApp } from 'vue'
import './index.css'
import App from './App.vue'
import { createPinia } from 'pinia'
import VCalendar from 'v-calendar'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { installMetrica, metrikaGoal } from '@/tools/metrika'
import PhoneInput from 'phone-input'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const DEFAULT_YANDEX_METRIKA_COUNTER = 91717113

// import resolveConfig from 'tailwindcss/resolveConfig'

const createPlApp = async (options) => {
  const {
    apiKey,
    element,
    cityId,
    yandexMetrikaCounter,
    selectedUnitId,
    colors,
    events,
    referenceId,
    viewSettings,
    mainColor,
    customViewSettings,
    canChangeUrl,
    selectedServicesIds,
    masterCategory,
    fourHands,
    currency,
    calltouch_session_id
  }: any = options
  let isLocalSentryInit = false

  if (mainColor) {
    document.documentElement.style.setProperty('--pl-black', mainColor)
    document.documentElement.style.setProperty('--black-600', mainColor)
  }

  window.yandexMetrikaCounter = yandexMetrikaCounter || DEFAULT_YANDEX_METRIKA_COUNTER

  const createParams = {
    cityId,
    selectedUnitId,
    apiKey,
    events,
    referenceId,
    viewSettings,
    customViewSettings,
    canChangeUrl,
    selectedServicesIds,
    fourHands,
    currency,
    calltouch_session_id
  }

  if (masterCategory) {
    createParams.masterCategory = masterCategory.split('_').map((item) => Number(item))
  }

  const plApp = createApp(App, createParams)

  plApp.use(createPinia())
  plApp.use(VCalendar)
  plApp.use(VueTelInput)
  plApp.use(PhoneInput)

  if (import.meta.env.PROD) {
    if (!window.__SENTRY__) {
      isLocalSentryInit = true

      Sentry.init({
        app: plApp,
        dsn: 'https://11f154129c314a0a901883a95a5b579a@logdata.pilki.com/12',
        integrations: [
          new Sentry.Replay()
        ],
        tracesSampleRate: 1.0,
        attachProps: true
      })
    }

    installMetrica()
    metrikaGoal('new_widget_start')

    plApp.config.errorHandler = (error) => {
      metrikaGoal('new_widget_error', error)

      if (isLocalSentryInit) {
        Sentry.captureException(error)
      }

      if (!isLocalSentryInit) {
        Sentry.withScope(function (scope) {
          scope.setTag('tag', 'pilki-widget')
          Sentry.captureException(error)
        })
      }
    }
  }
  plApp.mount(element)

  return plApp
}

export default (options = {}) => {
  if(!document.getElementById(options.element)) {
    return new Promise((resolve) => {
      if(!document.getElementById(options.element)) {
        setTimeout(() => {
          return resolve(createPlApp(options))
        }, 2000)
      } else {
        return resolve(createPlApp(options))
      }
    })
  } else {
    return createPlApp(options)
  }
}
