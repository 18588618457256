<script setup lang="ts">

</script>

<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.5" cy="13.5" r="13.5" fill="#302F2F"/>
    <path d="M9 9L18 18" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <path d="M18 9L9 18" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<style scoped lang="scss">

</style>
