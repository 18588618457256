<script lang="ts" setup>
import { computed } from 'vue'
import { useWindowsStore } from '@/state/windows'
import { useSettingsStore } from '@/state/settings'

const settingsStore = useSettingsStore()
const windowsStore = useWindowsStore()
</script>

<template>
  <div
    v-if="windowsStore.isMasterAndTimeSelected"
    class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-py-3 pl-mt-5 pl-text-center"
  >
    <div class="pl-text-green">
      Сумма заказа: <span class="pl-font-semibold">{{ windowsStore.orderSum.price }} {{ settingsStore.currency.currentCurrency }}</span>
    </div>
    <div class="pl-mt-3">
      Примерное время процедуры: <span class="pl-font-semibold">{{ windowsStore.orderSum.durationInMin }} мин</span>
    </div>

    <div
      v-if="windowsStore.orderDescription"
      class="pl-mt-3 pl-text-gray"
    >
      <span class="">{{ windowsStore.orderDescription }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
