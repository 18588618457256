import { HttpClient } from '@/tools/http-client'
import type { Booking } from '@/static-data/booking'
import type { GiftServiceTopMaster } from '@/static-data/service'

export class BookingApi extends HttpClient {
  public constructor() {
    const url = window.location.host === 'booking-next-2-staging.pilki.com' ? 'https://api-staging.pilki.com' : import.meta.env.PL_BASE_API
    super(url)
  }

  public createBooking = async (params: any, token: string): Promise<{ data: { booking: Booking, gift_services: GiftServiceTopMaster[] }}> => {
    return this.instance.post('/api/external/v1/bookings', params, {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  public getBookings = async (token: string): Promise<Booking[]> => {
    const { data } = await this.instance.get('/api/external/v1/bookings/undone', {
      headers: {
        authorization: `Bearer ${token}`
      }
    })

    return data
  }

  public cancelBooking = async (id: Booking['id'] ,token: string): Promise<Booking[]> => {
    return this.instance.put(`/api/external/v1/bookings/${id}/cancel`,
      { source: 'site' },
      {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }

  public sendReferId = async (referId: string, phone: string, token): Promise<void> => {
    return this.instance.post(`/cpa/reference`, {
      reference_id: referId,
      phone: `+${phone.replaceAll(/\D/gi, '')}`
    }, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }

  public sendPromoCode = async (promoCode: string, token: string, price: string, serviceIds: number[], companyId: number) => {
    return this.instance.post(`/api/external/v1/promo-codes/${promoCode}/check`, {
      price,
      services_list: serviceIds,
      company_id: companyId
    }, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }

  public checkGiftServiceByTopMaster = async (promoCode: string, token: string, serviceIds: number[], giftServiceIds: number[]): Promise<{ data: GiftServiceTopMaster[] }> => {
    return this.instance.post(`/api/external/v1/bookings/gift-services`, {
      services: serviceIds,
      gift_service_ids: giftServiceIds,
      promocode: promoCode
    }, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }

  public sendPromoCodeByGiftService = async (promoCode: string, token: string, price: string, serviceIds: number[], companyId: number, giftServiceId) => {
    return this.instance.post(`/api/external/v1/promo-codes/${promoCode}/check`, {
      price,
      services_list: serviceIds,
      company_id: companyId,
      selected_gift_id: giftServiceId,
    }, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }
}
