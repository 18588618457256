<script lang="ts" setup>
import PlButton from '@/components/pl-button.vue'
import { useUnitsList } from '@/hooks/units-list'

const {
  nextStep,
  isBtnNextStepDisable
} = useUnitsList()
</script>

<template>
  <pl-button
    :disable="isBtnNextStepDisable"
    label="Выбрать дату и мастера"
    @click="nextStep"
  />
</template>

<style lang="scss" scoped>

</style>
