import { reactive, ref } from 'vue'

import { defineStore } from 'pinia'
import type { Service } from '@/static-data/service'
import { DateTime } from 'luxon'
import type { User } from '@/static-data/user'
import type { Unit } from '@/static-data/unit'

export const useStore = defineStore('store', () => {
  const unit = ref<Unit | Record<string, any>>({})
  const service = reactive<Record<Service['id'], Service>>({})
  const date = ref(DateTime.local())
  const employerCategoryGroup = ref<number[]>([1,2,3])
  const user = ref<User | Record<string, any>>({})
  const windowsOrSchedules = reactive({ type: null, windows: [] })
  const selectedMasterAndTime = reactive<Record<string, number>>({})
  const apiKey = ref('')
  const referId = ref('')
  const currentCity = ref({})

  const setCity = (newCity) => currentCity.value = newCity
  const setReferId = (id: string) => referId.value = id
  const setApiKey = (newKey) => apiKey.value = newKey

  const setUser = (data: User) => {
    user.value = {
      ...user.value,
      ...data
    }
  }

  const setUnit = (newUnit: Unit) => {
    if (unit.value.id === newUnit.id) {
      unit.value = {}
      return
    }

    unit.value = newUnit
  }

  const toggleService = (serviceParam: Service) => {
    if (service[serviceParam.id]) {
      delete service[serviceParam.id]
      return
    }

    service[serviceParam.id] = serviceParam
  }

  const setDate = (newDate) => {
    date.value = DateTime.fromFormat(newDate.id, 'yyyy-MM-dd')
  }

  const toggleEmployerCategoryGroup = (id: number) => {
    const indexEmployerCategoryGroup = employerCategoryGroup.value.findIndex((category) => category === id)
    if (indexEmployerCategoryGroup > -1) {
      employerCategoryGroup.value.splice(indexEmployerCategoryGroup, 1)
      return
    }

    employerCategoryGroup.value.push(id)
  }

  const setWindowsOrSchedules = (type, windows) => {
    windowsOrSchedules.type = type
    windowsOrSchedules.windows = windows
  }

  const getSelectedWindowOrSchedule = () => {
    let result = null
    windowsOrSchedules.windows.forEach((window) => {
      window.forEach((localWindow) => {
        if (selectedMasterAndTime.employer_id === localWindow.employer_id && selectedMasterAndTime.begin_at === localWindow.begin_at) {
          result = localWindow
        }
      })
    })
    return result ? [result] : []
  }

  const setSelectedMasterAndTime = (type: string, value: number | null) => selectedMasterAndTime[type] = value

  return {
    user,
    setUser,
    unit,
    setUnit,
    service,
    toggleService,
    date,
    setDate,
    toggleEmployerCategoryGroup,
    employerCategoryGroup,
    setWindowsOrSchedules,
    windowsOrSchedules,
    getSelectedWindowOrSchedule,
    setSelectedMasterAndTime,
    apiKey,
    setApiKey,
    selectedMasterAndTime,
    referId,
    setReferId,
    setCity,
    currentCity
  }
})
