import { onBeforeMount, watch } from 'vue'
import { useCitiesStore } from '@/state/cities'

export const useCurrentCity = (cityId: string | number) => {
  const citiesStore = useCitiesStore()

  watch(
    () => cityId,
    async () => citiesStore.setCurrentCity(cityId)
  )

  return citiesStore.setCurrentCity(cityId)
}
