import { computed, ref, inject, watch, onMounted } from 'vue'
import { useUnitsStore } from '@/state/units'
import { useServicesStore } from '@/state/services'
import { isDesktop } from '@/tools/isDesctop'
import { MANICURE_ID, PEDICURE_ID } from '@/static-data/service'
import { useWindowsStore } from '@/state/windows'

export const useUnitsList = (props) => {
  const unitStore = useUnitsStore()
  const servicesStore = useServicesStore()
  const windowsStore = useWindowsStore()
  const searchValue = ref('')
  const nextStep = inject('next-step')
  const globalEvents = inject('global-events')

  const nextStepLocal = () => {
    let hasTwoCategories = false

    const services = Object.values(servicesStore.selectedServices).filter((service) => service.id === MANICURE_ID || service.id === PEDICURE_ID)

    if (services.length < 2) {
      windowsStore.setParallel(false)
    }

    nextStep()
  }

  const filteredUnitsByMapAndSearchValue = computed(() => {
    const localUnits = unitStore.units.filter((filterUnit) => filterUnit.isShowOnMap)

    if (!searchValue.value) {
      return localUnits
    }

    return localUnits.filter((unit) => unit.name.toLowerCase().includes(searchValue.value.toLowerCase()) || unit.address.toLowerCase().includes(searchValue.value.toLowerCase()))
  })

  const filteredUnitsBySearchValue = computed(() => {
    if (!searchValue.value) {
      return unitStore.units
    }

    return unitStore.units.filter((unit) => unit.name.toLowerCase().includes(searchValue.value.toLowerCase()) || unit.address.toLowerCase().includes(searchValue.value.toLowerCase()))
  })

  const isMapEmpty = computed(() => unitStore.units.filter((filterUnit) => filterUnit.isShowOnMap).length)

  const unitsList = computed(() => unitStore.units)

  const isBtnNextStepDisable = computed(() => {
    return !unitStore.selectedUnit.id || !Object.keys(servicesStore.selectedServices).length
  })

  const scrollToUnit = (unitId) => {
    document.getElementById('unit-list').scrollTop = 0
    const react = document.querySelectorAll(`[data-unit-id='${unitId}']`)[0]?.getBoundingClientRect()
    const reactUnitList = document.getElementById('unit-list').getBoundingClientRect()
    if (react) {
      document.getElementById('unit-list').scrollTo({top: react.top - reactUnitList.top, behavior: 'smooth'})
    }
  }

  const emitChangeCity = () => globalEvents?.emitChangeCity()

  watch(
    () => unitStore.selectedUnit,
    (newUnit) => {
      if (isDesktop()) {
        document.getElementById('unit-list').scrollTop = 0
      }
    }
  )

  onMounted(() => {
    if (unitStore.selectedUnit?.id) {
      scrollToUnit(unitStore.selectedUnit?.id)
    }
  })

  return {
    searchValue,
    filteredUnitsBySearchValue,
    filteredUnitsByMapAndSearchValue,
    units: unitStore.units,
    selectUnit: unitStore.setSelectedUnit,
    nextStep: nextStepLocal,
    isBtnNextStepDisable,
    unitsList,
    emitChangeCity
  }
}
