<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { isDesktop } from '@/tools/isDesctop'

const isMsgShow = ref(false)
let touchEnd = true

onMounted(() => {
  if (isDesktop()) {
    return
  }

  document.getElementById('pl-map-test-test')?.addEventListener('touchstart', (event) => {
    const isTwoFingerTouch = event.touches.length > 1;

    if (!isTwoFingerTouch) {
      touchEnd = false
      setTimeout(() => {
        if (!touchEnd) {
          isMsgShow.value = true
        }
      }, 100)
    }
  })

  document.getElementById('pl-map-test-test')?.addEventListener('touchend', () => {
    touchEnd = true
    isMsgShow.value = false
  })
})
</script>

<template>
    <div
      v-if="isMsgShow"
      ref="msgBlock"
      class="two-finger-msg"
    >
        <span>Чтобы переместить карту, проведите по ней двумя пальцами</span>
    </div>
</template>

<style lang="scss" scoped>
.two-finger-msg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: white;
  text-align: center;
  font-weight: bold;
  line-height: 30px;
  font-size: 20px;
  transition: all 0.3s;
}
</style>
