<script lang="ts" setup>
import { inject, ref } from 'vue'
import PlCheckbox from '@/components/pl-checkbox.vue'
import { useWindowsStore } from '@/state/windows'
import InfoBlock from '@/components/info-block.vue'
import Modal from '@/components/modal.vue'
import PlButton from '@/components/pl-button.vue'

const props = defineProps<{
  settings: Record<string, any>
}>()

const isModalShow = ref(false)
const windowsStore = useWindowsStore()
const changeStep = inject('change-step')

const changeStepLocal = () => {
  isModalShow.value = !isModalShow.value
  changeStep(0)
}

const updateModelFourHands = () => {
  if (props.settings?.showModalChangeStep) {
    isModalShow.value = !isModalShow.value
  }
}
</script>

<template>
  <info-block
    class-list="pl-mt-5"
  >
    <pl-checkbox
      :modelValue="windowsStore.parallel"
      class="pl-mt-2 pl-mb-2"
      :disabled="settings?.disable"
      @update:modelValue="windowsStore.setParallel"
      @click="updateModelFourHands"
    >
      4 руки
<!--      (+500 &#8381;)-->
    </pl-checkbox>
  </info-block>

  <modal
    v-model="isModalShow"
  >
    <div class="pl-font-semibold">Выбранные услуги</div>
    <div class="pl-mt-3">
      Услугу "4 руки" можно изменить только <br> на первом шаге
    </div>

    <pl-button
      label="Изменить"
      @click="changeStepLocal"
    />

    <button
      class="pl-bg-transparent pl-border-[1px] pl-border-solid pl-border-black pl-rounded-[2rem] pl-py-[8px] pl-w-full pl-mt-3"
      @click="isModalShow = !isModalShow"
    >
      Закрыть
    </button>
  </modal>
</template>

<style lang="scss" scoped>

</style>
