import { useDateStore } from '@/state/date'
import { useServicesStore } from '@/state/services'
import { useUnitsStore } from '@/state/units'
import { SERVER_FORMAT_DATE, WindowsApi } from '@/service/windows'
import { useEmployerCategoryGroup } from '@/state/employer-category-group'
import { useWindowsStore } from '@/state/windows'

export const useWindowsMiddleware = () => {
  const windowsApi = new WindowsApi()
  const dateStore = useDateStore()
  const servicesStore = useServicesStore()
  const unitsStore = useUnitsStore()
  const employerCategoryGroup = useEmployerCategoryGroup()
  const windowsStore = useWindowsStore()

  const loadWindows = () => {
    if (!unitsStore.selectedUnit.id || !Object.keys(servicesStore.selectedServices).length) {
      return {
        windows: [],
        schedules: []
      }
    }

    const params = {
      begin_at: dateStore.date.startOf('day').toFormat(SERVER_FORMAT_DATE),
      end_at: dateStore.date.endOf('day').toFormat(SERVER_FORMAT_DATE),
      service_ids: Object.keys(servicesStore.selectedServices),
      unit_ids: [unitsStore.selectedUnit.id],
      parallel: windowsStore.parallel ? 1 : 0,
      employer_category_group_ids: employerCategoryGroup.selectedEmployerCategoryGroup
    }

    return windowsApi.fetchWindows(params)
  }

  return {
    loadWindows
  }
}
