<script lang="ts" setup>
import { onMounted, ref, defineEmits, computed } from 'vue'
import { useBookingMiddleware } from '@/middleware/booking-middleware'
import type { Booking } from '@/static-data/booking'
import PlButton from '@/components/pl-button.vue'
import { ConvertDate } from '@/tools/convert-date'
import { metrikaGoal } from '@/tools/metrika'
import PlCheckbox from '@/components/pl-checkbox.vue'

const bookingMiddleware = useBookingMiddleware()
const bookings = ref<Booking[]>([])
const cancelBookingIds = ref<number[]>([])
const emits = defineEmits(['booking-canceled'])

const cancelBooking = async () => {
  if (cancelBookingIds.value.length) {
    for (const index in cancelBookingIds.value) {
      if (cancelBookingIds.value[index]) {
        await bookingMiddleware.cancelBooking(cancelBookingIds.value[index])
      }
    }
    metrikaGoal('visit_cancel')
    emits('booking-canceled')
  }
}

const formatDate = (date: Booking['begin_at']) => {
  return new ConvertDate(date).toDayFormatWithTime
}

const toggleCancelBookingIds = (id) => {
  if (!cancelBookingIds.value.includes(id)) {
    cancelBookingIds.value.push(id)
    return
  }

  const idIndex = cancelBookingIds.value.findIndex((item) => item === id)
  cancelBookingIds.value.splice(idIndex, 1)
}

const money = () =>
  new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  })
const formatMoney = (amount: number) => money().format(amount)

const totalCost = (booking) => {
  if (booking?.voucher?.amount) {
    return formatMoney(booking?.total - booking?.voucher?.amount)
  }

  return formatMoney(booking?.total)
}

onMounted(async () => {
  bookings.value = await bookingMiddleware.getBookings()
})
</script>

<template>
  <pl-checkbox
    v-for="(booking, index) in bookings"
    :key="booking.id"
    :modelValue="cancelBookingIds.includes(booking.id)"
    class="pl-mt-3"
    @update:modelValue="toggleCancelBookingIds(booking.id)"
  >
    <div
      class="pl-mt-5"
    >
      <div>
        {{ formatDate(booking.begin_at) }}
      </div>

      <div><span class="text-grey">Адрес:</span> {{ booking.services_with_window[0].window.unit.address }}</div>
      <div><span class="text-grey">Мастер:</span> {{ booking.services_with_window[0].window.employer.first_name }}</div>
      <div><span class="text-grey">{{ booking.services_with_window.length > 1 ? 'Услуги' : 'Услуга' }}:</span> {{ booking.services_with_window.map((item) => item.window.service.title).join(', ') }}</div>
      <div><span class="text-grey">Стоимость:</span> {{ totalCost(booking) }}</div>
    </div>
  </pl-checkbox>

  <div
    v-if="!bookings.length"
    class="pl-text-center pl-mt-[40px] pl-font-bold"
  >
    У вас нет активных заказов
  </div>

  <pl-button
    v-if="bookings.length"
    label="Отменить запись"
    @click="cancelBooking"
  />
</template>

<style lang="scss" scoped>
.cancel-booking {
  width: 100%;
  display: flex;
  background-color: white;
  margin-bottom: 2px;

  input {
    height: 24px;
    max-width: 50px;
    border: none;
    box-shadow: none;
  }

  .text {
    &-bold {
      font-weight: bolder;
    }

    &-grey {
      color: #717173;
    }

    &-date-from-now {
      color: #9d9d9f;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .py-2 {
    padding: 5px 0;
  }
}
</style>
