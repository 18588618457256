<script lang="ts" setup>
import { useUserStore } from '@/state/user'

const userStore = useUserStore()
</script>

<template>
  <div>
    <input
      type="text"
      class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-w-full pl-px-3 pl-h-[35px] pl-mt-5"
      :value="userStore.user.first_name"
      placeholder="Имя"
      @change="userStore.setUser({ first_name: $event.target.value })"
    >
    <input
      type="text"
      class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-w-full pl-px-3 pl-h-[35px] pl-mt-5"
      :value="userStore.user.last_name"
      placeholder="Фамилия"
      @input="userStore.setUser({ last_name: $event.target.value })"
    >
    <input
      type="text"
      class="pl-border-[1px] pl-border-solid pl-border-gray pl-rounded-md pl-w-full pl-px-3 pl-h-[35px] pl-mt-5"
      :value="userStore.user.email"
      placeholder="Email"
      @change="userStore.setUser({ email: $event.target.value })"
    >
  </div>
</template>

<style lang="scss" scoped>

</style>
