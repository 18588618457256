<script lang="ts" setup>
import { useWindowsHook } from '@/hooks/load-windows'
import InfoBlock from '@/components/info-block.vue'
import PlInput from '@/components/pl-input.vue'
import Spiner from '@/components/spinner.vue'
import SelectMasterTwoHands from '@/components/select-master-two-hands.vue'
import SelectMasterFourHands from '@/components/select-master-four-hands.vue'
import { useWindowsStore } from '@/state/windows'

const windowsHook = useWindowsHook()
const windowsStore = useWindowsStore()
</script>

<template>
  <info-block
    title="Выберите мастера"
    max-height="300"
    accordion
  >
    <template #header>
      <div class="pl-px-2">
        <pl-input
          class="pl-my-3"
          :model-value="windowsStore.searchMasterStr"
          @update:modelValue="windowsStore.setSearchMasterStr"
        />
      </div>
    </template>

    <spiner
      v-if="windowsStore.loading"
      class="pl-m-auto pl-w-8 pl-my-10"
    />

    <div v-if="!windowsStore.filteredTimes.length" class="pl-text-center pl-my-5">
      Нет доступных мастеров
    </div>

    <div v-if="!windowsStore.loading">
      <select-master-four-hands v-if="windowsStore.fourHands" />
      <select-master-two-hands
        v-if="!windowsStore.fourHands"
      />
    </div>
  </info-block>
</template>

<style lang="scss" scoped>

</style>
