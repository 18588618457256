import { reactive, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useServicesStore } from '@/state/services'
import { SERVICES_FOR_SELECT_FROM_MANICURE, SERVICES_FOR_SELECT_FROM_PEDICURE, DESIGN_FOR_MANICURE, MANICURE_SERVICE_ID, DESIGN_FOR_MANICURE_ONLY } from '@/state/services'
import { SMART_PEDICURE_IDS, PEDICURE_ID } from '@/static-data/service'
import { useSettingsStore } from '@/state/settings'

export const useServiceBlock = (props: Record<string, any>) => {
  const servicesStore = useServicesStore()
  const settingsStore = useSettingsStore()
  const descriptionRef = ref()
  const isBlockHidden = ref(props.defaultHidden)

  onClickOutside(descriptionRef, () => {
    showTooltipObj[300] = false
    showTooltipObj[500] = false
    showTooltipObj[1000] = false
  })

  const showTooltipObj = reactive({
    300: false,
    500: false,
    1000: false
  })

  const showTooltip = (service) => {
    switch (service.id) {
      case 1094:
        return 500
      case 1106:
        return 300
      case 1098:
        return 1000
    }
  }

  const checkIfNeedDisable = (service) => {
    let result = false

    settingsStore.settings?.disableGroup.forEach((disableGroup, index) => {
      const disableBlockIndex = !index ? 1 : 0

      disableGroup.forEach((id) => {
        if (Object.keys(useServicesStore).includes(id) && disableGroup[disableBlockIndex].includes(service.id)) {
          result = true
        }
      })
    })

    if (result) return result

    if (service.id === PEDICURE_ID && servicesStore.selectedServices[SMART_PEDICURE_IDS]) {
      return true
    }

    if (service.id === SMART_PEDICURE_IDS && servicesStore.selectedServices[PEDICURE_ID]) {
      return true
    }

    const selectedServiceIds = Object.keys(servicesStore.selectedServices).map((id) => parseInt(id))

    if (MANICURE_SERVICE_ID === service.id) {
      for (let i = 0; i < selectedServiceIds.length; i++) {
        if (DESIGN_FOR_MANICURE_ONLY.includes(selectedServiceIds[i])) {
          return true
        }
      }
    }

    if (SERVICES_FOR_SELECT_FROM_MANICURE.includes(service.id)) {
      for (let i = 0; i < selectedServiceIds.length; i++) {
        if (DESIGN_FOR_MANICURE.includes(selectedServiceIds[i])) {
          return true
        }
      }
    }

    if (SERVICES_FOR_SELECT_FROM_PEDICURE.includes(service.id)) {
      return servicesStore.selectedServices[1096] || servicesStore.selectedServices[1108] || servicesStore.selectedServices[1100]
    }

    return false
  }

  return {
    showTooltip,
    descriptionRef,
    isBlockHidden,
    showTooltipObj,
    servicesStore,
    checkIfNeedDisable
  }
}
