import { useUnitsStore } from '@/state/units'
import { computed } from 'vue'

export const useUnitDescription = (props) => {
  const unitStore = useUnitsStore()

  const unitClass = computed(() => {
    if (!unitStore.selectedUnit.id) {
      return
    }

    if (unitStore.selectedUnit.id === props.unit.id) {
      return 'pl-unit-active'
    }

    return 'pl-text-gray'
  })

  return {
    unitClass
  }
}
