import { useStore } from '@/state/store'

export const useSetApiKey = () => {
  const store = useStore()

  const setApiKey = (newApiKey: string) => store.setApiKey(newApiKey)

  return {
    setApiKey
  }
}
