<script lang="ts" setup>
import { defineEmits } from 'vue'

const props = defineProps<{ label: string, disable?: boolean }>()

const emits = defineEmits(['click'])
const click = () => {
  if (props.disable) {
    return
  }

  emits('click')
}
</script>

<template>
  <button
    class="pl-mt-5"
    :disabled="disable"
    @click="click"
  >
    {{ label }}
  </button>
</template>

<style lang="scss" scoped>
button {
  color: #fff;
  border-radius: 2rem;
  background: var(--pl-black);
  width: 100%;
  padding: 10px 0;

  &:disabled {
    background: #ABBAC6;
    cursor: not-allowed;
  }
}
</style>
