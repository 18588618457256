import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUnitsMiddleware } from '@/middleware/units'
import type { Unit } from '@/static-data/unit'
import { useCitiesStore } from '@/state/cities'

export const useUnitsStore = defineStore('units-store', () => {
  const citiesStore = useCitiesStore()
  const units = ref<Unit[]>([])
  const selectedUnit = ref<Unit | Record<string, any>>({})
  const selectedUnitId = ref(null)

  const unitsMiddleware = useUnitsMiddleware()

  const loadUnits = async () => {
    units.value = await unitsMiddleware.fetchAllUnits(citiesStore.currentCity.id)

    if (selectedUnitId.value) {
      const currentUnit = units.value.find((unit) => unit.id === parseInt(selectedUnitId.value))

      if (currentUnit) {
        selectedUnit.value = currentUnit
      }

      selectedUnitId.value = null
    }

    units.value.forEach((unit) => unit.isShowOnMap = true)
  }

  const setSelectedUnitId = (unitId: string | number) => selectedUnitId.value = unitId

  const setSelectedUnit = (newUnit: Unit) => {
    if (selectedUnit.value.id === newUnit.id) {
      selectedUnit.value = {}
      return
    }

    selectedUnit.value = newUnit
  }

  return {
    setSelectedUnitId,
    loadUnits,
    setSelectedUnit,
    selectedUnit,
    units
  }
})
