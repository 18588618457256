import {watch, ref, onMounted, computed} from 'vue'
import { useDateStore } from '@/state/date'
import { useWindowsStore } from '@/state/windows'
import { DateTime } from 'luxon'
import type { Window } from '@/static-data/window'

export const useWindowsHook = () => {
  const dateStore = useDateStore()
  const windowsStore = useWindowsStore()
  // const workTime = ref([])
  // const masters = ref([])
  // const loading = ref(false)
  // const selectedMaster = ref(null)
  // const selectedTime = ref(null)
  // const windowsOrSchedules = ref([])
  // let filterBy = ref(null)

  // const setSelectedMaster = (newMaster) => {
  //   if (!filterBy.value) {
  //     filterBy.value = 'master'
  //   }
  //
  //   if (selectedMaster.value?.id === newMaster.id) {
  //     selectedMaster.value = null
  //     windowsStore.setSelectedMaster(null)
  //     filterBy.value = null
  //     return
  //   }
  //
  //   selectedMaster.value = newMaster
  //   windowsStore.setSelectedMaster(newMaster)
  // }

  // const setSelectedTime = (newTime) => {
  //   if (!filterBy.value) {
  //     filterBy.value = 'time'
  //   }
  //
  //   if (selectedTime.value?.id === newTime.id) {
  //     selectedTime.value = null
  //     windowsStore.setSelectedTime(null)
  //     filterBy.value = null
  //     return
  //   }
  //
  //   selectedTime.value = newTime
  //   windowsStore.setSelectedTime(newTime)
  // }

  // const fetchAndSetWindows = async () => {
  //   loading.value = true
  //
  //   windowsOrSchedules.value = await windowsStore.loadWindows()
  //   setFilteredWindows()
  //
  //   loading.value = false
  // }

  // const setFilteredWindows = () => {
  //   workTime.value = generateWorkTimes()
  //   masters.value = generateMasters()
  //   console.log(workTime.value.length)
  // }

  // const generateMasters = () => {
  //   const windowsOrSchedulesWithUniqKey: Record<number, Window> = windowsOrSchedules.value.reduce((result: Record<number, Window>, window: Window[]) => {
  //     window.forEach((item) => {
  //       result[item.employer_id] = item
  //     })
  //     return result
  //   }, {})
  //
  //   return Object.values(windowsOrSchedulesWithUniqKey).sort((prev: Window, next: Window) => Number(next.rating) - Number(prev.rating))
  // }

  // const generateWorkTimes = () => {
  //   let windowsOrSchedulesWithUniqKey = windowsOrSchedules.value.reduce((result, window: Window[]) => {
  //     window.forEach((item) => {
  //       if (filterBy.value === 'master' && item.employer_id === selectedMaster.value.employer_id) {
  //         result[item.begin_at] = {
  //           renderId: `select-time-${item.id}`,
  //           ...item,
  //           formatBeginAt: DateTime.fromSeconds(item.begin_at).toFormat('HH:mm')
  //         }
  //       }
  //       if (filterBy.value !== 'master') {
  //         result[item.begin_at] = {
  //           renderId: `select-time-${item.id}`,
  //           ...item,
  //           formatBeginAt: DateTime.fromSeconds(item.begin_at).toFormat('HH:mm')
  //         }
  //       }
  //     })
  //     return result
  //   }, {})
  //
  //   return Object.values(windowsOrSchedulesWithUniqKey)
  // }

  // watch(
  //   () => {
  //     return windowsStore.selectedMaster || windowsStore.selectedTime
  //   },
  //   () => setFilteredWindows()
  // )

  watch(
    () => dateStore.date,
    () => windowsStore.loadWindows()
  )

  onMounted(() => windowsStore.loadWindows())

  return {
    // workTime,
    // masters,
    // loading,
    // setSelectedTime,
    // setSelectedMaster,
    // selectedMaster,
    // selectedTime,
    // storeSelectedMaster: windowsStore.selectedMaster
  }
}
