export const DEFAULT_CITY_ID = 1

export interface City {
  id: number
  name: string
  name_show: string
  phone: string
  address: string
  address_short: string
  rating_voices: number
  rating_total: string
  longitude: number
  latitude: number
  timezone: string
  cashless_pay: boolean
  payment_message: string
  is_parallel_possible: boolean
}
