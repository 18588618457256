import { watch, onBeforeMount } from 'vue'
import { useUnitsStore } from '@/state/units'
import { useCitiesStore } from '@/state/cities'

export const useUnitsMap = () => {
  const unitsStore = useUnitsStore()
  const citiesStore = useCitiesStore()

  watch(
    () => citiesStore.currentCity,
    () => unitsStore.loadUnits(),
    {
      deep: true
    }
  )

  onBeforeMount(async () => {
    await unitsStore.loadUnits()
  })
}
