import { CitiesApi } from '@/service/cities'

export function useCitiesMiddleware () {
  const citiesApi = new CitiesApi()

  const getCitiesList = async () => {
    const { data } = await citiesApi.citiesList()
    return data
  }

  return {
    getCitiesList
  }
}
