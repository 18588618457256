import { UnitsApi } from '@/service/units'

export const useUnitsMiddleware = () => {
  const unitsApi = new UnitsApi()

  const fetchAllUnits = (cityId: string | number) => unitsApi.fetchAllUnits(cityId)

  return {
    fetchAllUnits
  }
}
