<script lang="ts" setup>
import UnitList from '@/components/unit-list.vue'
import { useCreateMap } from '@/hooks/create-map'
import { useUnitsMap } from '@/hooks/units'
import { isDesktop } from '@/tools/isDesctop'
import TwoFingerMsg from '@/components/two-finger-msg.vue'
import SelectedUnit from '@/components/selected-unit.vue'
import { useUnitsStore } from '@/state/units'
import { useUnitsList } from '@/hooks/units-list'
import PlButton from "@/components/pl-button.vue";

const props = defineProps<{
  settings: Record<string, any>
}>()

useUnitsMap()
const unitsStore = useUnitsStore()
const { mapHeight } = useCreateMap(props)
const {
  units,
  filteredUnitsBySearchValue,
  filteredUnitsByMapAndSearchValue,
  emitChangeCity
} = useUnitsList(props)
</script>

<template>
  <selected-unit
    v-if="!isDesktop() && unitsStore.selectedUnit?.id"
    :show-modal="false"
  />

  <div
    v-if="props.settings?.isShowChangeCityBtn && (!filteredUnitsByMapAndSearchValue.length || !filteredUnitsBySearchValue.length)"
    class="pl-block md:pl-hidden"
  >
    <div class="pl-text-sm pl-font-semibold pl-px-2 pl-text-center pl-mt-5">
      На карте нет ни одной студии
    </div>

    <pl-button
      label="Изменить город?"
      @click="emitChangeCity"
    />
  </div>

  <div class="pl-relative pl-w-full pl-h-full pl-h-full pl-overflow-hidden pl-mt-5">
    <div
      id="pl-map-test-test"
      :style="{ width: '100%', height: mapHeight }"
    >
    </div>

    <two-finger-msg />

    <unit-list
      class="pl-absolute pl-right-0 pl-w-[300px] pl-top-0 pl-bg-whitest pl-hidden md:pl-block"
      show-next-button
      :settings="settings"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>
