import { UserApi } from '@/service/user'
import { useUserStore } from '@/state/user'

export function useUserMiddleware() {
  const userApi = new UserApi()
  const userStore = useUserStore()

  const authAndGetUser = async (code: string, phone: string): Promise<void> => {
    const token = await userApi.authUser(code, phone)
    const user = await userApi.getUser(token)
    userStore.setUser({ ...user, token, phone })
    return user
  }

  const updateUser = async () => {
    await userApi.updateUser(userStore.user.token, {
      last_name: userStore.user.last_name,
      first_name: userStore.user.first_name,
      email: userStore.user.email,
    })
  }

  return {
    authAndGetUser,
    updateUser
  }
}
